import { AuthProvider } from './AuthContext'; // assuming it's in the root directory
import App from './App';

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
