export const headers = [
    {
      key: 'obcina_naziv',
      header: 'Občina',
    },
    {
      key: 'naselje_naziv',
      header: 'Naselje',
    },
    {
      key: 'ulica_naziv',
      header: 'Ulica',
    },
    {
      key: 'st_gospodinjstev',
      header: 'Št. gospodinjstev',
    },
    {
      key: 'hs_stevilka',
      header: 'Hišna št.',
    },
    {
      key: 'postni_okolis_sifra',
      header: 'Poštna št.',
    },
    {
      key: 'postni_okolis_naziv',
      header: 'Pošta',
    },
    {
      key: 'st_stan_ali_dela',
      header: 'Št. stan./dela',
    },
    {
      key: 'vrsta_subjekta',
      header: 'Vrsta subjekta',
    },
    {
      key: 'prikljucki',
      header: 'Priključki',
    },
    {
      key: 'odziv',
      header: 'Odziv',
    },
    {
      key: 'opombe',
      header: 'Opombe',
    },
    {
      key: 'datum',
      header: 'Datum',
    },
    {
      key: 'geometry',
      header: 'Usmeri',
    },
  ];

  export const defaultColumnSelections = {
    // Only these will be selected by default in TerenTable
      TerenTable:[
        'ulica_naziv',
        'naselje_naziv',
        'st_gospodinjstev',
        // 'postni_okolis_sifra',
        // 'postni_okolis_naziv',
      ], 
      PlanMapPage:[
        'ulica_naziv', 
        'hs_stevilka', 
        'postni_okolis_sifra', 
        'postni_okolis_naziv', 
        'st_stan_ali_dela', 
        'vrsta_subjekta', 
        'prikljucki',
        'odziv',
        'opombe',
        'datum',
        'geometry'
      ], 
      // Add more presets for different tables or components if needed
    };