import React from 'react';
import AkcijeAdmin from './AkcijeAdmin';

const AdminPage = () => {

    return (
        <div>
            <h1>Akcije</h1>
            <AkcijeAdmin />
        </div>
    );
};

export default AdminPage;