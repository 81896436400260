

const validateMaticnaSt = (maticnaSt) => {

    // Check if the value is exactly 10 characters long. 7 numbers (6+control), 1 letter or number, 2 numbers
    const regex = /^(\d{7})([A-Z0-9])(\d{2})$/;
    const isFormatValid = regex.test(maticnaSt);
    if (!isFormatValid) {
      return false;
    }

    // Extract the first 6 digits and the control character (7th digit)
    const baseNumber = maticnaSt.slice(0, 6).split('').map(Number);
    const controlCharacter = maticnaSt[6].toUpperCase();
    //const endingNumber = maticnaSt.slice(7);

    // Calculation of the control number using modulo 11
    const weights = [7, 6, 5, 4, 3, 2];
    let total = 0;
    
    for (let i = 0; i < 6; i++) {
      total += baseNumber[i] * weights[i];
    }

    let remainder = total % 11;
    let controlNumber = 11 - remainder;
    if (controlNumber === 10) {
      controlNumber = 0;
    }

    // Check if the calculated control number matches the provided control character
    const isValid = controlCharacter === controlNumber.toString();

    return isValid;
};

export default validateMaticnaSt;