import React, { useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
//import pdfWorker from 'pdfjs-dist/build/pdf.worker.mjs';

GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

const PdfViewer = ( { url } ) => {
  const canvasRef = useRef(null);
  //GlobalWorkerOptions.workerSrc = '/path/to/pdf.worker.js'; // Update this path
  console.log("PDF url: ", url);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        if (!url) return; // Exit if no URL is provided
        //const pdf = await pdfjsLib.getDocument('/zahteva-za-prenos-narocniske-stevilke-k-drugemu-operaterju.pdf').promise;
        //const pdf = await pdfjsLib.getDocument(url).promise;
        console.log("Fetching the PDF file from url: ", url);
        const pdf = await pdfjsLib.getDocument({ url }).promise;
        const page = await pdf.getPage(1);

        const viewport = page.getViewport({ scale: 1 });
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
      } catch (error) {
        console.error('Error occurred while loading and rendering PDF:', error);
      }
    };

    fetchPdf();
  }, []);

  return (<canvas className='pdf-container' ref={canvasRef}></canvas>);

};

export default PdfViewer;