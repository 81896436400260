import React, { useState, useEffect } from 'react';
import { ProgressBar } from '@carbon/react';
import fetchDataPlans from '../../components/TerenTable/fetchDataPlans';
import fetchDataPlanTeren from '../../components/TerenTable/fetchDataPlanTeren';
import { useAuth } from '../../AuthContext';
import './TerrainsSummary.css'; // Import the CSS file for styling

const TerrainsSummary = () => {
    const { currentUserId, pb } = useAuth();
    const [plans, setPlans] = useState([]);
    const [totalAllTerrains, setTotalAllTerrains] = useState(0);
    const [terrainsWithResponseAll, setTerrainsWithResponseAll] = useState(0);
    const [planProgress, setPlanProgress] = useState({});

    useEffect(() => {
        const fetchPlans = async () => {
            const planResult = await fetchDataPlans(pb, currentUserId);
            setPlans(planResult.data);

            // Fetch data for all plans to calculate total sum
            const allTerrains = [];
            const progressData = {};
            for (const plan of planResult.data) {
                const planData = await fetchDataPlanTeren(pb, 1, 1000, plan.id);
                allTerrains.push(...planData.data);

                const flattenedData = planData.data.map(item => ({
                    ...item,
                    ...item.expand,
                    ...item.expand.teren,
                }));
                const totalTerrainsForPlan = flattenedData.length;
                const terrainsWithResponseForPlan = flattenedData.filter(item => item.odziv).length;
                progressData[plan.id] = {
                    totalTerrains: totalTerrainsForPlan,
                    terrainsWithResponse: terrainsWithResponseForPlan
                };
            }

            const flattenedAllData = allTerrains.map(item => ({
                ...item,
                ...item.expand,
                ...item.expand.teren,
            }));

            setTotalAllTerrains(flattenedAllData.length);
            setTerrainsWithResponseAll(flattenedAllData.filter(item => item.odziv).length);
            setPlanProgress(progressData);
        };

        fetchPlans();
    }, [currentUserId, pb]);

    const progressPercentageAll = totalAllTerrains > 0 ? (terrainsWithResponseAll / totalAllTerrains) * 100 : 0;

    return (
        <div className="terrains-summary">
            <div className="full-width-progress">
                <ProgressBar
                    label="Skupni napredek obiskov"
                    helperText={`${terrainsWithResponseAll} od ${totalAllTerrains} obiskov`}
                    value={progressPercentageAll}
                />
            </div>
            <div className="progress-bars-container">
                {plans.map((plan) => (
                    <div key={plan.id} className="progress-bar-item">
                        <h4>{plan.ime}</h4>
                        <ProgressBar
                            label={`Napredek odzivov za ${plan.ime}`}
                            helperText={`${planProgress[plan.id]?.terrainsWithResponse || 0} od ${planProgress[plan.id]?.totalTerrains || 0} obiskov`}
                            value={planProgress[plan.id]?.totalTerrains > 0 ? (planProgress[plan.id].terrainsWithResponse / planProgress[plan.id].totalTerrains) * 100 : 0}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TerrainsSummary;