import React, { useState, useEffect } from 'react';
import { DataTable, Pagination, TableSelectAll, TableSelectRow, Button, Modal, TextInput } from '@carbon/react';
import { Map, NextFilled } from '@carbon/icons-react'
import transformCoordinates from './transformCoordiantes';
import PrikljuckiIconComponent from './PrikljuckiIconComponent';
import proj4 from 'proj4';
import './_teren-table.scss'; // Import the SCSS file
import { useAuth } from '../../AuthContext';
import createObiskiRecord from './createObiskiRecord';
import { useNavigate } from 'react-router-dom';
import { usePlanMapContext } from '../../contexts/PlanMapContext';
import generateCryptoRandomString from '../generateCryptoRandomString';


const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;

const TableComponent = ({
  tableName,
  data,
  filteredHeaders,
  currentPage,
  totalItems,
  pageSize,
  handlePaginationChange,
  selectedRows,
  setSelectedRows,
  showSelect,
  isAgent = false,
}) => {

  // Calculate the date 6 months ago
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  // On modal close, set the refetchPlans state to true
  // TODO: This is a workaround to force the table to refetch data
  // TODO: You should update the data offline
  const {
    refetchPlanData, setRefetchPlanData
    //   isModalOpen, setIsModalOpen
  } = usePlanMapContext();

  // Format the date to the ISO string and split to get the date part
  const sixMonthsAgoDateString = sixMonthsAgo.toISOString().split('T')[0];

  const navigate = useNavigate();

  const navigateToSalePage = (data) => {
    navigate('/vnos', { state: { initialData: data } });
  };


  // Update the state to hold the current 'teren' data
  const [currentTerenData, setCurrentTerenData] = useState(null);

  // Where you handle the Prodano button click
  const handleProdanoClick = () => {

    // print currentTerenData
    console.log('handleProdanoClick currentTerenData:', currentTerenData);


    // Navigate to the sale page
    navigateToSalePage(currentTerenData);
    // Close the modal if it's open
    setIsObiskModalOpen(false);
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedRows(data.map(row => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (rowId, isSelected) => {
    if (isSelected) {
      setSelectedRows(prevState => [...prevState, rowId]);
    } else {
      setSelectedRows(prevState => prevState.filter(id => id !== rowId));
    }
  };

  // print all the headers available
  //console.log('filteredHeaders', filteredHeaders);

  const openInMaps = (geometry) => {
    const [lat, lon] = transformCoordinates(geometry);
    if (lat && lon) {
      const mapsUrl = `https://maps.google.com/?q=${lat},${lon}`;
      window.open(mapsUrl, '_blank');
    }
  };



  const ObiskModal = ({ isOpen, onClose, onSubmit, initialData, shouldFetchData }) => {
    const { isAuthenticated, currentUserId, pb, login, logout } = useAuth();
    const [odziv, setOdziv] = useState(initialData?.odziv || '');
    const [opombe, setOpombe] = useState(initialData?.opombe || '');
    const [datum, setDatum] = useState(initialData?.datum || new Date().toISOString().split('T')[0]);
    const [inputValidationError, setInputValidationError] = useState(false);
    const [obiskId, setObiskId] = useState(null);

    const handleBigButtonClick = (response) => {
      setOdziv(response);

      // print odziv
      console.log('handleBigButtonClick odziv:', response);

      if (['DG', 'NŽ', 'NN'].includes(response)) {
        if (!opombe) {
          setInputValidationError(true); // Set the error state to true
        } else {
          setInputValidationError(false); // Clear the error state
          handleSubmit(response);
        }
      } else {
        handleSubmit(response);
      }
      // Open text input for Opombe for specific responses
      // You might want to manage the visibility of the text input based on the response
    };

    // Effect hook to fetch data when the modal opens
    useEffect(() => {
      // log the initialData
      console.log('ObiskModal initialData:', initialData);

      if (isOpen) {
        // Fetch the "obiski" record linked to the "teren"
        const fetchObiskiData = async () => {
          const today = new Date().toISOString().split('T')[0];
          const filterString = `teren = "${currentTerenData.id}" && datum >= "${sixMonthsAgoDateString}"`;
          try {
            const resultList = await pb.collection('obiski').getList(1, 1, { filter: filterString });
            if (resultList.items.length > 0) {
              const obiskiData = resultList.items[0];
              // log the obiskiData
              console.log('ObiskModal obiskiData:', obiskiData);
              setOdziv(obiskiData.odziv);
              setOpombe(obiskiData.opombe);
              setObiskId(obiskiData.id);
            } else {
              // log that no obiskiData was found
              console.log('ObiskModal no obiskiData found');
              setOdziv('');
              setOpombe('');
            }
          } catch (error) {
            console.error('Error fetching obiski data:', error);
          }
        };

        fetchObiskiData();
      }
    }, [isOpen, shouldFetchData, initialData, pb]);

    const handleSubmit = (response) => {
      // Check if "opombe" is not empty for specific responses
      if (['DG', 'NŽ', 'NN'].includes(response) && !opombe.trim()) {
        setInputValidationError(true); // Prevent form submission and show error
        return; // Exit the function to prevent calling onSubmit
      }

      // print odziv
      console.log('handleSubmit odziv:', response);

      const data = {
        teren: currentTerenData.id,
        agent: currentUserId,
        odziv: response,
        opombe: opombe,
        datum: datum,
        // Include any other necessary fields
      };

      console.log('ObiskModal handleSubmit data:', data);

      onSubmit(pb, data, obiskId);
      onClose();
    };

    const handleInputChange = (e) => {
      setOpombe(e.target.value);
      setInputValidationError(false); // Clear the error state
    }

    return (
      <Modal
        open={isOpen}
        onRequestClose={onClose}
        modalHeading="Obisk"
        primaryButtonText="Prekliči"

        className='modal-size'
        //className='modal-size hide-primary-button'
        //initialData={currentTerenData}
        onRequestSubmit={onClose}
      >

        <div className="obisk-modal-content">
          {/* Big buttons for responses */}
          <TextInput
            id="opombe"
            labelText="Opombe"
            value={opombe}
            onChange={handleInputChange}
            placeholder="Vnesite opombe"
            invalid={inputValidationError}

          />
          <br />
          <Button
            className={`obisk-modal-button dogovor ${odziv === 'DG' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('DG')}>Dogovor</Button>
          <Button
            className={`obisk-modal-button nezeli ${odziv === 'NŽ' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('NŽ')}>Ne želi</Button>
          <Button
            className={`obisk-modal-button ninavoljo ${odziv === 'NN' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('NN')}>Ni na voljo</Button>
          <Button
            className={`obisk-modal-button zema ${odziv === 'ŽI' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('ŽI')}>Že ima</Button>
          <Button
            className={`obisk-modal-button nidoma ${odziv === 'ND' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('ND')}>Ni doma</Button>
          <Button
            className={`obisk-modal-button prodano ${odziv === 'Prodano' ? 'active-response' : ''}`}
            onClick={() => handleProdanoClick()}>Prodano</Button>

        </div>
      </Modal>
    );
  };


  // Inside PlanMapApp
  const [isObiskModalOpen, setIsObiskModalOpen] = useState(false);
  const [currentObiskData, setCurrentObiskData] = useState(null);

  const handleObiskClick = (rowData) => {
    // Set currentTerenData to the clicked row data
    const originalData = data.find(item => item.id === rowData.id);
    setCurrentTerenData(originalData);
    console.log('ObiskClick originalData:', originalData);

    // Check if a record for the same day exists
    // If it does, set currentObiskData to that record
    // If it doesn't, set currentObiskData to null
    const today = new Date().toISOString().split('T')[0];
    const obiski = originalData.obiski;
    // if obiski is undefined ...
    if (!obiski) {
      setCurrentObiskData(null);
    } else {
      /*const obiskiToday = obiski.filter(obisk => obisk.datum === today);
      if (obiskiToday.length > 0) {
        setCurrentObiskData(obiskiToday[0]);
      } else {
        setCurrentObiskData(null);
      }*/
      setCurrentObiskData(obiski[0]);
    }
    // Decide if we should fetch data based on if an "obiski" record exists
    const shouldFetchData = !!currentObiskData;

    setIsObiskModalOpen(true);
  };

  const handleObiskModalClose = () => {
    setIsObiskModalOpen(false);
    setCurrentObiskData(null);
  };

  const handleObiskModalSubmit = async (pb, obiskData, obiskiId) => {
    let updatedObiski = [...(currentTerenData.obiski || [])]; // new code

    if (currentObiskData) {
      // Update existing record

      await pb.records.update('obiski', currentObiskData.id, obiskData);
      updatedObiski = updatedObiski.map(obisk => obisk.id === obiskiId ? { ...obisk, ...obiskData } : obisk); // new code
    } else {
      // Create new record
      //await pb.records.create('obiski', obiskData);
      await createObiskiRecord(pb, obiskData, obiskiId);
      const newObisk = { ...obiskData, id: obiskiId }; // new code
      updatedObiski.push(newObisk); // new code
    }
    const updatedTerenData = { ...currentTerenData, obiski: updatedObiski }; // new code

    setRefetchPlanData(true); // TODO: This is a workaround to force the table to refetch data
    // TODO: You should update the data offline

    setIsObiskModalOpen(false);
    // You may want to refresh or update your data here to reflect the change
  };

  return (
    <><Pagination
      page={currentPage}
      totalItems={totalItems}
      pageSize={pageSize}
      pageSizes={[10, 20, 30, 40, 50]}
      pageRangeText={(_current, total) => `od ${total} ${total === 1 ? 'stran' : 'strani'}`}
      itemRangeText={(min, max, total) => `${min}–${max} od ${total} zapisov`}
      itemsPerPageText="Zapisov na stran"
      backwardText="Prejšnja stran"
      forwardText="Naslednja stran"
      onChange={(event) => {
        // extract the new page and pageSize from the event
        // then update currentPage and pageSize accordingly
        handlePaginationChange(event);
      }} /><DataTable
        rows={data}
        headers={filteredHeaders}
      //selectedRows={selectedRows} 
      //onSelectionChange={handleRowSelectionChange}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getTableProps,
          getTableContainerProps
        }) => {
          // print data
          console.log('data', data);
          console.log('rows', rows);
          return (
            <TableContainer title={tableName} {...getTableContainerProps()}>
              <Table {...getTableProps()} aria-label="Teren table">
                <TableHead>
                  <TableRow>
                    {showSelect && (
                      <TableSelectAll
                        {...getSelectionProps({
                          onClick: (e) => handleSelectAll(e.target.checked)
                        })}
                        checked={selectedRows.length === data.length}
                        indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                      />
                    )}
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                    {isAgent && (
                      <TableHeader>
                        Obisk
                      </TableHeader>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    // Log the row to see its structure
                    //console.log(row);

                    // Optionally, if you expect 'prikljucki' to be in 'row.original'
                    // if (row.original) {
                    //   console.log('row.original.prikljucki:', row.original.prikljucki);
                    // }
                    //row.original = row;

                    return (
                      <TableRow key={row.id} {...getRowProps({ row })}>
                        {showSelect && (
                          <TableSelectRow
                            {...getSelectionProps({
                              row,
                              onClick: () => handleRowSelect(row.id, !selectedRows.includes(row.id))
                            })}
                            checked={selectedRows.includes(row.id)}
                          />
                        )}
                        {row.cells.map((cell) => {
                          //print the cell info
                          //console.log('cell', cell);
                          //console.log('row', row);

                          // Check if this cell corresponds to the prikljucki data
                          if (cell.info.header === 'prikljucki') { // Adjust the condition based on your actual data structure
                            // Render PrikljuckiIconComponent for each prikljucki record in the teren record

                            // print the prikljucki records
                            //console.log('row.original.prikljucki', row.original.prikljucki);

                            return (
                              <TableCell key={cell.id}>
                                <div className="prikljucki-container">
                                  {cell.value ? cell.value.map(prikljuckiRecord => (
                                    <PrikljuckiIconComponent key={prikljuckiRecord.id} prikljucki={prikljuckiRecord} />
                                  )) : null}
                                </div>
                              </TableCell>
                            );

                          } else if (cell.info.header === 'geometry') {
                            // if geometry is not present, return empty cell
                            if (!cell.value) {
                              return (
                                <TableCell key={cell.id}></TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={cell.id}>
                                  <Button
                                    renderIcon={() => <Map size={20} />}
                                    iconDescription="Usmeri"
                                    hasIconOnly
                                    onClick={() => openInMaps(cell.value)}
                                    size='sm'
                                    kind='ghost'
                                  />
                                </TableCell>
                              );
                            }
                          } else {
                            return (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            );
                          }
                        })}
                        {isAgent && (
                          <TableCell key={`${row.id}-obisk`}>
                            <Button
                              renderIcon={() => <NextFilled size={20} />} // Pass the desired size as a prop
                              iconDescription="Obisk"
                              hasIconOnly
                              onClick={() => handleObiskClick(row)}
                              size='md'
                              kind='primary'
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      <ObiskModal
        isOpen={isObiskModalOpen}
        onClose={handleObiskModalClose}
        onSubmit={handleObiskModalSubmit}
        initialData={currentObiskData}
        shouldFetchData={!!currentObiskData}
      />
    </>
  );
};

export default TableComponent;
