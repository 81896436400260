import React, { useState, useRef, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, ButtonSet, IconButton } from '@carbon/react';
//import 'carbon-components/css/carbon-components.min.css';
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from '@carbon/icons-react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

// This uses React-PDF library to render the PDF file
// https://github.com/wojtekmaj/react-pdf
// check "support for annotations" in the documentation
// also check "support for text layer" in the documentation

// To create PDF in the browser, you can use: https://github.com/diegomura/react-pdf

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewerWithOverlay = ({ pdfBlobUrl, signatureLocations, currentSignatureIndex, pageNumberParam }) => {
  const [numPages, setNumPages] = useState(0);
  // page number defined as page in signature location - take the currentSignatureIndex location
  //console.log('signatureLocations', signatureLocations);
  //console.log('currentSignatureIndex', currentSignatureIndex);
  //const [pageNumber, setPageNumber] = useState(signatureLocations[currentSignatureIndex].page);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const [showNav, setShowNav] = useState(true);
  const viewerRef = useRef(null);
  const [scaledPageHeight, setScaledPageHeight] = useState(0);


  useEffect(() => {
    setPageNumber(pageNumberParam);
  }, [pageNumberParam]);

  // Inside your PdfViewerWithOverlay component, right before the return statement

signatureLocations.forEach((location, index) => {
    if (location.page === pageNumber) {
      // Calculate the scaled dimensions
      const scaledX = location.coordinates[0] * zoomLevel;
      const scaledY = location.coordinates[1] * zoomLevel;
      const scaledWidth = location.size[0] * zoomLevel;
      const scaledHeight = location.size[1] * zoomLevel;
  
      // Calculate the lower-right corner based on the scaled dimensions
      const lowerRightX = scaledX + scaledWidth;
      const lowerRightY = scaledY + scaledHeight;
  
      // Console out the details
      //console.log(`Signature Location ${index + 1}:`);
      //console.log(`Upper-Left Corner: (${scaledX}, ${scaledY})`);
      //console.log(`Lower-Right Corner: (${lowerRightX}, ${lowerRightY})`);
      //console.log(`Width: ${scaledWidth}, Height: ${scaledHeight}`);
    }
  });

  const handlePageLoadSuccess = ({ pageNumber, originalWidth, originalHeight, width, height }) => {
    //console.log(`Page ${pageNumber} Loaded`);
    //console.log(`Original Width: ${originalWidth}, Original Height: ${originalHeight}`);
    //console.log(`Scaled Width: ${width}, Scaled Height: ${height}`);
    // Upper-left corner is at (0, 0)
    //console.log('Upper-Left Corner: (0, 0)');
    // Lower-right corner coordinates depend on the scaled width and height
    //console.log(`Lower-Right Corner: (${width}, ${height})`);

    console.log('Page Loaded (load success)');
    setScaledPageHeight(height); //
  };

  //const handleMouseEnter = () => setShowNav(true);
  //const handleMouseLeave = () => setShowNav(false);

    const goToPrevPage = () => {
    if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
    }
    };

    const goToNextPage = () => {
    if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
    }
    };

    const zoomIn = () => {
    setZoomLevel(zoomLevel * 1.1);
    };

    const zoomOut = () => {
    setZoomLevel(zoomLevel / 1.1);
    };


  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log('Document Loaded (load success)');
    setNumPages(numPages);
  };

  // TODO: PDF size values are not the same as read from PDF. Right now hardcoded in the config file.
  // TODO: Implement the logic to calculate the size of the signature placeholder based on the actual PDF size

  return (
    <div style={{ position: 'relative', overflow: 'auto' }}>
      <Document file={pdfBlobUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} scale={zoomLevel} onLoadSuccess={handlePageLoadSuccess}/>
      </Document>
      {signatureLocations.length > currentSignatureIndex && signatureLocations[currentSignatureIndex].page === pageNumber && (
    (() => {
        const signature = signatureLocations[currentSignatureIndex];
        const scaledX = signature.coordinates[0] * zoomLevel;
        // Use the scaled page height to adjust the Y position
        const scaledY = scaledPageHeight - (signature.coordinates[1] * zoomLevel) - (signature.size[1] * zoomLevel);
        return (
            <div
                key={currentSignatureIndex}
                style={{
                    border: '2px solid red',
                    position: 'absolute',
                    left: `${scaledX}px`,
                    top: `${scaledY}px`,
                    width: `${signature.size[0] * zoomLevel}px`,
                    height: `${signature.size[1] * zoomLevel}px`,
                }}
            />
        );
    })()
)}

      { (
        <>
      <ButtonSet>
        <IconButton label="Prejšnja stran" className="custom-icon-button" kind="primary" size="lg" width="32" renderIcon={ChevronLeft} onClick={goToPrevPage} disabled={pageNumber <= 1}>
        </IconButton>
        <IconButton label="Naslednja stran" className="custom-icon-button" kind="primary" renderIcon={ChevronRight} onClick={goToNextPage} disabled={pageNumber >= numPages}>
        </IconButton>
        <IconButton label="Povečaj" className="custom-icon-button" renderIcon={ZoomIn} onClick={zoomIn}></IconButton>
        <IconButton label="Pomanjšaj" className="custom-icon-button" renderIcon={ZoomOut} onClick={zoomOut}></IconButton>
      </ButtonSet>
      <div style={{ marginTop: '1rem' }}>
        Stran {pageNumber} od {numPages}
      </div>
        </>
      )}
    </div>
  );
};

export default PdfViewerWithOverlay;