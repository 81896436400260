import React, { useEffect, useState } from 'react';
import {
  Form,
  TextInput,
  Button,
  FileUploaderDropContainer,
  Loading,
  InlineNotification,
  Tile,
  Stack,
  Grid,
  Column,
} from '@carbon/react';
import { UserAvatar } from '@carbon/react/icons';
import { useAuth } from '../../AuthContext'; 
import config from '../../config';

const ProfilePage = () => {
  const { pb, currentUserId, isAuthenticated } = useAuth();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [file, setFile] = useState(null);
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  useEffect(() => {
    // Fetch user data if the user is authenticated
    const fetchUserData = async () => {
      if (isAuthenticated && currentUserId) {
        try {
          const userRecord = await pb.collection('users').getFirstListItem(`id="${currentUserId}"`, {
            expand: 'role', // Adjust with appropriate expansion if needed
          });
          setUserData(userRecord);
        } catch (err) {
          setError('Ni mogoče pridobiti podatkov o uporabniku');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError('Uporabnik ni prijavljen');
      }
    };

    fetchUserData();
  }, [isAuthenticated, currentUserId, pb]);

  const handlePasswordChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const updateData = {
      oldPassword: passwordData.oldPassword,
      password: passwordData.newPassword,
      passwordConfirm: passwordData.confirmPassword,
    };

    if (file) {
      updateData.avatar = file;
    }

    try {
      const updatedRecord = await pb.collection('users').update(currentUserId, updateData);
      setSuccess('Profil uspešno posodobljen!');
      setUserData(updatedRecord);
    } catch (err) {
      setError('Posodobitev profila ni uspela');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading description="Nalagam profil..." withOverlay={true} />;
  }

  return (
    <div className="profile-page">
      {error && <InlineNotification kind="error" title="Napaka" subtitle={error} />}
      {success && <InlineNotification kind="success" title="Uspeh" subtitle={success} />}

      <Grid narrow>
        <Column sm={4} md={8} lg={4}>
          <Tile>
            <Stack gap={4} orientation="vertical">
              {userData.avatar ? (
                <img
                  src={config.pocketbase_test+`/api/files/users/${currentUserId}/${userData.avatar}`}
                  alt="Uporabniški avatar"
                  className="profile-avatar"
                  style={{ borderRadius: '50%', width: '100px', height: '100px' }}
                />
              ) : (
                <UserAvatar size={32} />
              )}
              <h4>{userData.name}</h4>
              <p>{userData.email}</p>
              <p>Vloga: {userData.role}</p>
              <FileUploaderDropContainer
                labelText="Nov avatar"
                onChange={handleFileChange}
                accept={['image/png', 'image/jpeg']}
              />
            </Stack>
          </Tile>
        </Column>
        <Column sm={4} md={8} lg={8}>
          <Form onSubmit={handleSubmit}>
            <Tile>
              <h4>Spremeni geslo</h4>
              <TextInput
                id="oldPassword"
                type="password"
                labelText="Trenutno geslo"
                name="oldPassword"
                value={passwordData.oldPassword}
                onChange={handlePasswordChange}
                required
              />
              <TextInput
                id="newPassword"
                type="password"
                labelText="Novo geslo"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                required
              />
              <TextInput
                id="confirmPassword"
                type="password"
                labelText="Potrdi novo geslo"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
              <Button type="submit" className="mt-4">Shrani spremembe</Button>
            </Tile>
          </Form>
        </Column>
      </Grid>
    </div>
  );
};

export default ProfilePage;