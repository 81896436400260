import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Button } from '@carbon/react';
import { Camera, Reset, Renew } from '@carbon/icons-react';

function CameraComponent({ onCapture }) {
    const [isCaptured, setIsCaptured] = useState(false);
    const [photo, setPhoto] = useState(null);
    const hasPhoto = useRef(false);
    const videoRef = useRef(null);
    const streamRef = useRef(null);

    // Camera facing mode state and toggle function
    const [facingMode, setFacingMode] = useState("environment");  // default to back camera
    const toggleCamera = () => {
        setFacingMode(prevMode => (prevMode === "user" ? "environment" : "user"));
    };

    const startCamera = async () => {
      try {
        console.log('Starting camera...');
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Error accessing the camera", error);
      }
    };

    useEffect(() => {
      if (!(hasPhoto.current)) {
        console.log('HasPhoto is false. Starting camera...');
        startCamera();
      }

      return () => {
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
      };
    }, []);

    const capturePhoto = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

      const dataUrl = canvas.toDataURL('image/jpeg');
      //onCapture(dataUrl); // Pass the data URL up to the parent component
      setPhoto(dataUrl);
      hasPhoto.current = true;
      console.log('hasPhoto on Capture:', hasPhoto.current);
      setIsCaptured(true);

      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      onCapture(dataUrl);
    };

    const retakePhoto = () => {
      setIsCaptured(false);
      setPhoto(null);
      hasPhoto.current = false;
      console.log('hasPhoto on Retake:', hasPhoto.current);
      onCapture(null);
      startCamera();
    };

    return (
      <div style={{ position: 'relative', textAlign: 'center' }}>
        {!isCaptured ? (
          <video ref={videoRef} autoPlay playsInline width="100%"></video>
        ) : (
          <img src={photo} alt="Captured" style={{ width: '100%' }} />
        )}
        <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 10 }}>
          {!isCaptured ? (
            <>
              <Button
                onClick={toggleCamera}

                kind="primary"
                hasIconOnly
                renderIcon={() => <Renew size={32} />}
                size='xl'
              >
                Toggle Camera
              </Button>
              <Button
                onClick={capturePhoto}

                kind="primary"
                hasIconOnly
                renderIcon={() => <Camera size={32} />}
                size='xl'
              >
                Capture
              </Button>
            </>
          ) : (
            <>
              <Button kind="secondary"
                onClick={retakePhoto}
                style={{ marginRight: '10px' }}
                hasIconOnly
                renderIcon={() => <Reset size={32} />}
                size='xl'
              >
                Ponovi
              </Button>
            </>
          )}
        </div>
      </div>
    );


  }

export default React.memo(CameraComponent);
