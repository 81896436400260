import proj4 from 'proj4';

// Define the projection just as it's defined in MapComponent
proj4.defs("EPSG:3794", "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

const transformCoordinates = (geometry) => {
  // Extract the coordinates by matching anything between parentheses
  const match = geometry.match(/\(([^)]+)\)/);
  if (!match) {
    console.error('Invalid geometry format');
    return null;
  }

  // Extract the coordinate part and split it into an array
  const coords = match[1].split(' ').map(Number);
  if (coords.length !== 3) {
    console.error('Expected 3 coordinates');
    return null;
  }

  const [x, y, z] = coords;
  
  // Transform the coordinates
  const [lon, lat] = proj4("EPSG:3794", "EPSG:4326", [x, y]);
  
  // The coordinates are reversed in the database, so return [lon, lat]
  return [lat, lon];
};

export default transformCoordinates;