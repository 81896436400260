import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import {
    DataTable,
    DataTableSkeleton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    Button,
    TextInput,
    DatePicker,
    DatePickerInput,
    Dropdown,
    Modal,
} from '@carbon/react';
import './CSS/AkcijeAdmin.css';
import { useAuth } from '../../AuthContext';
import { ComboBox } from 'carbon-components-react';

const AkcijeAdmin = () => {

    const { pb, currentUserId, isAuthenticated } = useAuth();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [paketiOptions, setPaketiOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);
    const [formValues, setFormValues] = useState({
        popust: '',
        velja_od: '',
        velja_do: '',
        paket: '',
    });

    // Fetch paketi options for dropdown
    useEffect(() => {
        const fetchPaketiOptions = async () => {
            try {
                const resultList = await pb.collection('paketi').getFullList({ sort: '-created' });
                setPaketiOptions(resultList.map((paket) => ({ id: paket.id, name: paket.paket })));
            } catch (error) {
                console.error('Ni uspelo pridobiti možnosti paketov:', error);
            }
        };

        fetchPaketiOptions();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const resultList = await pb.collection('paketi_akcije').getList(page, perPage, {
                expand: 'paket',
                filter: 'created >= "2022-01-01 00:00:00"',
                sort: '-created',
            });
            setData(resultList.items);
            setTotalItems(resultList.totalItems);
        } catch (error) {
            console.error('Ni uspelo pridobiti podatkov:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleInputChange = (field, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const handleFormSubmit = async () => {
        try {
            if (isEditMode) {
                await pb.collection('paketi_akcije').update(currentRecordId, formValues);
            } else {
                await pb.collection('paketi_akcije').create(formValues);
            }
            setIsModalOpen(false);
            setIsEditMode(false);

            fetchData();
        } catch (error) {
            console.error('Ni uspelo ustvariti/posodobiti zapisa:', error);
        }
    };

    const handleAddNewRecord = () => {
        setFormValues({
            popust: '',
            velja_od: '',
            velja_do: '',
            paket: '',
        });
        setIsEditMode(false);
        setIsModalOpen(true);
    };

    const handleEditRecord = (record) => {
        setFormValues({
            popust: record.popust,
            velja_od: formatDate(record.velja_od),
            velja_do: formatDate(record.velja_do),
            paket: record.paket,
        });
        setCurrentRecordId(record.id);
        setIsEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteRecord = async (recordId) => {
        if (window.confirm('Ali ste prepričani, da želite izbrisati ta zapis?')) {
            try {
                await pb.collection('paketi_akcije').delete(recordId);
                fetchData();
            } catch (error) {
                console.error('Ni uspelo izbrisati zapisa:', error);
            }
        }
    };

    return (
        <div>
            <Button onClick={handleAddNewRecord}>Dodaj Akcijo</Button>

            <Modal
                open={isModalOpen}
                modalHeading={isEditMode ? "Uredi zapis Paketi Akcije" : "Dodaj nov zapis Paketi Akcije"}
                primaryButtonText={isEditMode ? "Posodobi zapis" : "Dodaj zapis"}
                secondaryButtonText="Prekliči"
                onRequestClose={() => setIsModalOpen(false)}
                onRequestSubmit={handleFormSubmit}
            >
                <TextInput
                    id="popust"
                    labelText="Popust"
                    value={formValues.popust}
                    onChange={(e) => handleInputChange('popust', e.target.value)}
                />
                <DatePicker
                    dateFormat="Y-m-d"
                    datePickerType="single"
                    onChange={(dates) => handleInputChange('velja_od', dates[0].toLocaleDateString('en-CA'))}
                >
                    <DatePickerInput
                        id="velja_od"
                        placeholder="yyyy-mm-dd"
                        labelText="Velja Od"
                        value={formValues.velja_od}
                    />
                </DatePicker>
                <DatePicker
                    dateFormat="Y-m-d"
                    datePickerType="single"
                    onChange={(dates) => handleInputChange('velja_do', dates[0].toLocaleDateString('en-CA'))}
                >
                    <DatePickerInput
                        id="velja_do"
                        placeholder="yyyy-mm-dd"
                        labelText="Velja Do"
                        value={formValues.velja_do}
                    />
                </DatePicker>
                <br />
                <ComboBox
                    id="paket"
                    label="Izberite Paket"
                    items={paketiOptions}
                    itemToString={(item) => (item ? item.name : '')}
                    onChange={({ selectedItem }) => handleInputChange('paket', selectedItem.id)}
                    selectedItem={paketiOptions.find((item) => item.id === formValues.paket)}
                />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />


            </Modal>

            <TableContainer title="Seznam akcij" description="Seznam akcij na paketih">
                {loading ? (
                    <DataTableSkeleton rowCount={perPage} columnCount={6} />
                ) : (
                    <DataTable
                        rows={data.map((record) => ({
                            id: record.id,
                            popust: record.popust,
                            velja_od: formatDate(record.velja_od),
                            velja_do: formatDate(record.velja_do),
                            paket: record.expand?.paket?.paket || 'Neznano',
                            actions: (
                                <div>
                                    <Button
                                        onClick={() => handleEditRecord(record)}
                                        size="small"
                                        kind="ghost"
                                    >
                                        Uredi
                                    </Button>
                                    <Button
                                        onClick={() => handleDeleteRecord(record.id)}
                                        size="small"
                                        kind="ghost"
                                        className="delete-button"
                                    >
                                        Izbriši
                                    </Button>
                                </div>
                            ),
                        }))}
                        headers={[
                            { key: 'popust', header: 'Popust' },
                            { key: 'velja_od', header: 'Velja Od' },
                            { key: 'velja_do', header: 'Velja Do' },
                            { key: 'paket', header: 'Paket' },
                            { key: 'actions', header: 'Akcije' },
                        ]}
                        render={({ rows, headers, getHeaderProps, getRowProps }) => (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })} key={header.key}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow {...getRowProps({ row })} key={row.id}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    />
                )}
            </TableContainer>

            <Pagination
                page={page}
                pageSize={perPage}
                pageSizes={[10, 20, 30, 50]}
                totalItems={totalItems}
                onChange={({ page, pageSize }) => {
                    handlePageChange(page);
                    handlePerPageChange(pageSize);
                }}
            />
        </div>
    );
};

export default AkcijeAdmin;