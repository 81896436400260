import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { Button, ButtonSet } from '@carbon/react'; // Importing Button components

// Setup worker
pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

const SimplePdfViewer = ({ pdfBlobUrl, signatureLocations, currentSignatureIndex, pageNumberParam}) => {
  const canvasRef = useRef();
  const overlayCanvasRef = useRef(); // Ref for the overlay canvas
  const [pdf, setPdf] = useState(null);
  const [pageNumber, setPageNumber] = useState(pageNumberParam);
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    setPageNumber(pageNumberParam);
  }, [pageNumberParam]);

  useEffect(() => {
    const loadPdf = async () => {
      if (pdfBlobUrl) {
        try {
          const loadedPdf = await pdfjsLib.getDocument(pdfBlobUrl).promise;
          setPdf(loadedPdf);
          setPageNumber(pageNumberParam); // Start with the first page
          setNumPages(loadedPdf.numPages);
        } catch (error) {
          console.error('Error loading PDF: ', error);
        }
      }
    };

    loadPdf();
  }, [pdfBlobUrl]);

  useEffect(() => {
    const renderPage = async (num) => {
      if (pdf) {
        try {
          const page = await pdf.getPage(num);
          const viewport = page.getViewport({ scale: 1.0 });
          const canvas = canvasRef.current;
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: canvas.getContext('2d'),
            viewport,
          };

          await page.render(renderContext).promise;

          // After rendering the PDF page, draw the signature placeholders
          // Clear the overlay canvas every time you render a new page
          const overlayCanvas = overlayCanvasRef.current;
          const overlayCtx = overlayCanvas.getContext('2d');
          overlayCanvas.width = canvas.width; // This also clears the canvas
          overlayCanvas.height = canvas.height;
          overlayCtx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

          drawSignaturePlaceholders();
        } catch (error) {
          console.error(`Error rendering page ${num}: `, error);
        }
      }
    };

    console.log('Rendering page:', pageNumber);
    renderPage(pageNumber);
  }, [pdf, pageNumber]);

  const drawSignaturePlaceholders = async () => {
    if (signatureLocations && signatureLocations.length > 0 && currentSignatureIndex < signatureLocations.length) {
      const location = signatureLocations[currentSignatureIndex];
      console.log('Drawing signature placeholder:', location);
      if (location.page === pageNumber) { // Only draw if the current page matches the location's page
        const overlayCanvas = overlayCanvasRef.current;
        const ctx = overlayCanvas.getContext('2d');

        // Set the overlay canvas size to match the main canvas explicitly, without relying on CSS scaling
        const canvas = canvasRef.current;
        overlayCanvas.style.width = '100%';
        overlayCanvas.style.height = '100%';
        overlayCanvas.width = canvas.width; // Use the actual width and height used for drawing
        overlayCanvas.height = canvas.height;

        // Adjust coordinates and size according to the scale used for rendering the PDF page
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport( { scale: 1.0 } );
        const scale = viewport.scale; // Ensure this is the scale at which the PDF page is rendered
        const scaledX = location.coordinates[0] * scale;
        const scaledY = location.coordinates[1] * scale;
        const scaledWidth = location.size[0] * scale;
        const scaledHeight = location.size[1] * scale;

        //overlayCanvas.width = canvasRef.current.width;
        //overlayCanvas.height = canvasRef.current.height;

        // Clear previous drawings
        ctx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height);

        // Draw the red square
        ctx.beginPath();
        ctx.rect(scaledX, scaledY, scaledWidth, scaledHeight);
        //ctx.rect(location.coordinates[0], location.coordinates[1], location.size[0], location.size[1]);
        ctx.strokeStyle = 'red';
        ctx.stroke();
      }
    }
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  //     <canvas ref={canvasRef}></canvas>
  return (
    <>
<div className="pdf-viewer-container" style={{ position: 'relative', height: 'auto', marginBottom: '20px' }}>
  <canvas ref={canvasRef}></canvas>
  <canvas ref={overlayCanvasRef} style={{ position: 'absolute', left: '0', top: '0', width: '100%', height: '100%' }}></canvas>
</div>

      <ButtonSet>
        <Button kind="secondary" onClick={goToPrevPage} disabled={pageNumber <= 1}>
          Prejšnja stran
        </Button>
        <Button kind="secondary" onClick={goToNextPage} disabled={pageNumber >= numPages}>
          Naslednja stran
        </Button>
      </ButtonSet>
      <div style={{ marginTop: '1rem' }}>
        Stran {pageNumber} od {numPages}
      </div>
    </>
  );
};

export default SimplePdfViewer;
