import React from 'react';

const NotAuthorizedPage = () => {
    return (
        <div>
            <h1>Not Authorized</h1>
            <p>You are not authorized to access this page.</p>
        </div>
    );
};

export default NotAuthorizedPage;