// App.js
import { Button, Content, Theme } from '@carbon/react';
import React, { Component } from 'react';
import AgentronHeader from './components/AgentronHeader';
import { Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './content/LandingPage';
import PlanMapPage from './content/PlanMapPage';
import SalePage from './content/SalePage';
import AuthenticationPage from './content/AuthenticationPage';
import { useAuth } from './AuthContext'; // assuming it's in the root directory
import DocumentSignPage from './content/DocumentSignPage';
import PdfViewer from './content/DocumentSignPage/PdfViewer';
import AnalyticsPage from './content/AnalyticsPage';
import XLSUploadPage from './content/XLSUploadPage';
import { PlanMapProvider } from './contexts/PlanMapContext'; // Import the context provider
import './themes/agentron-theme.scss';
import Interesanti from './content/Leads/Interesanti';
import LeadButton from './content/Leads/LeadButton';
import NarocilaPage from './content/Narocila/VsaNarocila';
import NotAuthorizedPage from './content/NotAuthorized/NotAuthorizedPage';
import AdminPage from './content/AdminPage/AdminPage';
import ProfilePage from './content/UserPages/ProfilePage';

function ProtectedRoute({ children, allowedRoles }) {
  const { isAuthenticated, currentUserRole } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(currentUserRole)) {
    // If user role is not allowed, redirect to the Not Authorized page
    return <Navigate to="/not-authorized" />;
  }

  return children;
}

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <PlanMapProvider> {/* Wrap your application with the PlanMapProvider */}
      <>
        {isAuthenticated && (
          <Theme theme="g100">
            <AgentronHeader />
          </Theme>
        )}
        <Content>
          <LeadButton />
          <Routes>
            <Route path="/login" element={<AuthenticationPage />} />
            <Route path="/*" element={
              <ProtectedRoute>
                <Routes>
                  <Route index element={<PlanMapPage />} />
                  <Route path="/admin" element={<LandingPage />} />
                  <Route path="/plan" element={<PlanMapPage />} />
                  <Route path="/vnos" element={<SalePage />} />
                  <Route path="/podpis" element={<DocumentSignPage />} />
                  <Route path="/analitika" element={<AnalyticsPage />} />
                  <Route path="/xls" element={<XLSUploadPage />} />
                  <Route path="/interesanti" element={<Interesanti />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/narocila" element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <NarocilaPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/admin-dash" element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <AdminPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/not-authorized" element={<NotAuthorizedPage />} />
                </Routes>
              </ProtectedRoute>
            }
            />
          </Routes>
        </Content>
      </>
    </PlanMapProvider>
  );
}

export default App;