export const mapStyleVertical = {
    position: 'sticky',
    top: 0,
    height: '100vh', // 100% of the viewport height
    flex: '0 0 400px', // The map will have a fixed width of 400px
    zIndex: 1,
    marginLeft: '15px'
  };

export const mapStyleHorizontal = {
    position: 'sticky',
    top: 0,
    //height: '100vh', // 100% of the viewport height
    height: '700px',
    // the map will fill allavailable width
    flex: '1 1 0px',
    zIndex: 1,
};

  
export const tableStyle = (isSidebarVisible) => ({
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    maxWidth: isSidebarVisible ? 'calc(100% - 600px)' : 'calc(100% - 400px)', // 200px (sidebar) + 400px (map)
});


export const sidebarStyle = {
  minWidth: '200px',
  maxWidth: '200px',
  //padding: '1em',
  marginRight: '15px',
  flex: '0 0 200px',
};

