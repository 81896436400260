function formatDateString(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

// TODO: deduplicate prikljucki records in the database! - then remove this function
const deduplicatePrikljuckiRecords = (records) => {

  const uniqueRecords = new Map();

  records.forEach((record) => {
    // Create a unique key based on the fields
    const uniqueKey = `${record.opt_vrsta_prikljucka_id}-${record.opt_priklop_id}-${record.minimalna_zmogljivost}-${record.maticna}`;
    // If this key hasn't been seen before, add the record to the uniqueRecords
    if (!uniqueRecords.has(uniqueKey)) {
      uniqueRecords.set(uniqueKey, record);
    }
  });

  // Return only the unique values
  return Array.from(uniqueRecords.values());
};

const fetchDataPlanTeren = async (pb, page, size, planId) => {
    try {
      let filterString = `plan = "${planId}"`;

      let sortString = `naselje_naziv, ulica_naziv, hs_stevilka, st_stan_ali_dela`; // Fields to sort by

      //const resultList = await pb.collection('plan_teren').getList(page, size, {
      //const resultList = await pb.collection('plan_teren').getList(1, 10000, {
        const resultList = await pb.collection('plan_teren').getFullList({
        filter: filterString,
        //sort: sortString,
        expand: "teren, teren.prikljucki, teren.obiski_via_teren, teren.prikljucki.maticne_sifrant",
      });

      console.log('resultList:', resultList);

      const terenRecordsWithPrikljuckiAndObiski = await Promise.all(
        //resultList.items.map(async terenRecord => {
          resultList.map(async terenRecord => {

          terenRecord.obiski = terenRecord.expand.teren.expand.obiski_via_teren;

          terenRecord.prikljucki = deduplicatePrikljuckiRecords(terenRecord.expand.teren.expand.prikljucki);

          for (let prikljuckiRecord of terenRecord.prikljucki ) {
            if (prikljuckiRecord.maticna) {
              //////////////////////////
              //const { ikona, naziv } = await fetchIcon(pb, prikljuckiRecord.maticna);
              const iconUrl = pb.files.getUrl(prikljuckiRecord.expand.maticne_sifrant, prikljuckiRecord.expand.maticne_sifrant.ikona /*, { token }*/);
              //prikljuckiRecord.icon = prikljuckiRecord.expand.maticne_sifrant.ikona;
              prikljuckiRecord.icon = iconUrl;
              prikljuckiRecord.naziv = prikljuckiRecord.expand.maticne_sifrant.naziv;
            }
          }

          let obiskiRecords = terenRecord.obiski;
          // Check if there are any obiski records and add the latest 'odziv' to the teren record
          if (obiskiRecords !== undefined && Array.isArray(obiskiRecords) && obiskiRecords.length > 0) {
            // Assuming you want the last odziv from the obiski records
            const latestObiskiRecord = obiskiRecords[obiskiRecords.length - 1];
            terenRecord.odziv = latestObiskiRecord.odziv;
            terenRecord.opombe = latestObiskiRecord.opombe;
            terenRecord.datum = formatDateString(latestObiskiRecord.datum);
          } else {
            terenRecord.odziv = null; // or some default value
            terenRecord.opombe = null; // or some default value
            terenRecord.datum = null; // or some default value
          }
          return terenRecord;

        })
      );

      return {
        //data: resultList.items,
        data: terenRecordsWithPrikljuckiAndObiski,
        currentPage: resultList.page,
        totalItems: resultList.totalItems,
      };
  
    } catch (err) {
      console.error('Error fetching data:', err);
      return {
        data: [],
        currentPage: 1,
        totalItems: 0,
      };
    }
  };
  
  export default fetchDataPlanTeren;
  