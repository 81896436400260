// createObiskiRecord.js

import generateCryptoRandomString from "../generateCryptoRandomString";

/**
 * Creates or updates an 'obiski' record in the database.
 * @param {Object} pb - The database instance or API handler.
 * @param {Object} recordData - The data for the new or existing 'obiski' record.
 * @param {string} [recordData.id] - The ID of the record to update, if updating.
 * @param {boolean} hasNarocilo - Indicates if the 'obiski' record has a related 'narocilo' record.
 * @returns {Promise<Object>} - The result of the record creation or update.
 */
const createOrUpdateObiskiRecord = async (pb, recordData, obiskiId, hasNarocilo) => {
  try {
    let result;

    console.log('Record data:', recordData);
    console.log('Obiski ID:', obiskiId);

    if (obiskiId) {
      // If an ID is provided, update the existing record
      //const { id, ...updateData } = recordData;

      /*
      This is how to update a post and add a new related record - example for adding a record in table named "tags"
      const post = await pb.collection('posts').update('POST_ID', {
          // append single tag
          'tags+': 'TAG_ID1',
      
          // append multiple tags at once
          'tags+': ['TAG_ID1', 'TAG_ID2'],
      })
      */

      if (recordData.odziv === "Prodano") {
        //if (hasNarocilo) {
          result = await pb.collection('obiski').update(obiskiId, recordData);
        //} else {
        //  // Generate a new 'narocilo' ID
        //  const newNarociloId = generateCryptoRandomString();
        //  // Add the new 'narocilo' ID to the 'obiski' record
        //  recordData['narocilo+'] = newNarociloId;
        //  // Update the 'obiski' record with the new 'narocilo'
        //  result = await pb.collection('obiski').update(obiskiId, recordData);
       // }

        console.log('Record updated successfully:', result);
      }
    } else {
      // If no ID is provided, create a new record

      // Check for Geolocation support
      if (navigator.geolocation) {
        // Promise wrapper for getCurrentPosition
        const getCurrentPosition = (options = {}) => {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
          });
        };

        try {
          // Request current position
          const position = await getCurrentPosition();
          const { latitude, longitude } = position.coords;

          // Add geolocation data to recordData
          recordData.geolatitude = latitude;
          recordData.geolongitude = longitude;
        } catch (geoError) {
          console.warn('Geolocation permission denied or error occurred:', geoError);
          // Handle the error or use default values
          recordData.geolatitude = null;
          recordData.geolongitude = null;
        }
      } else {
        console.warn('Geolocation is not supported by your browser');
        recordData.geolatitude = null;
        recordData.geolongitude = null;
      }

      if (recordData.odziv !== "Prodano") {
        console.log('Record data before saving:', recordData);
        result = await pb.collection('obiski').create(recordData);
      } else {
        console.log('Record data before saving (Prodano):', recordData);
        // Generate a new 'narocilo' ID
        const newNarociloId = generateCryptoRandomString();
        console.log('New narocilo ID:', newNarociloId);
        // Add the new 'narocilo' ID to the 'obiski' record
        recordData['narocilo+'] = newNarociloId;
        // Update the 'obiski' record with the new 'narocilo'
        result = await pb.collection('obiski').create(recordData);
        console.log('Record created successfully:', result);
      }
      console.log('Record created successfully:', result);
    }

    return {
      success: true,
      data: result,
    };

  } catch (err) {
    console.error('Error in record operation:', err);
    return {
      success: false,
      error: err,
    };
  }
};

export default createOrUpdateObiskiRecord;
