const fetchDataUsers = async (pb) => {
    try {
      // fetch a paginated records list
    const resultList = await pb.collection('users').getList(1, 500, {
      filter: '',
    });

    return {
      data: resultList.items,
    };
  
    } catch (err) {
      console.error('Error fetching data:', err);
      return {
        data: [],
      };
    }
  };
  
  export default fetchDataUsers;
  