import React, { createContext, useContext, useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import config from './config.json';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUserFullName, setCurrentUserFullName] = useState(localStorage.getItem('currentUserFullName') || null);
    const [currentUserPhoneNum, setCurrentUserPhoneNum] = useState(localStorage.getItem('currentUserPhoneNum') || null);
    const [currentUserEmail, setCurrentUserEmail] = useState(localStorage.getItem('currentUserEmail') || null);
    const [currentUserId, setCurrentUserId] = useState(localStorage.getItem('currentUserId') || null);
    const [currentUserRole, setCurrentUserRole] = useState(localStorage.getItem('currentUserRole') || null);
    const [isTestDatabase, setIsTestDatabase] = useState(JSON.parse(localStorage.getItem('isTestDatabase')) || false);
    const [isAuthenticated, setIsAuthenticated] = useState(false); 

    const createPocketBaseInstance = (useTestDatabase) => {
        const databaseUrl = useTestDatabase ? config.pocketbase_test : config.pocketbase;
        const pbInstance = new PocketBase(databaseUrl);
        pbInstance.autoCancellation(false);

        const authData = JSON.parse(localStorage.getItem('pb_auth') || '{}');
        if (authData.token && authData.record) {
            pbInstance.authStore.save(authData.token, authData.record);
            setIsAuthenticated(pbInstance.authStore.isValid);
        }

        return pbInstance;
    };

    const [pb, setPb] = useState(() => createPocketBaseInstance(isTestDatabase));

    useEffect(() => {
        localStorage.setItem('isTestDatabase', JSON.stringify(isTestDatabase));
        setPb(createPocketBaseInstance(isTestDatabase));
    }, [isTestDatabase]);

    useEffect(() => {
        if (currentUserFullName) localStorage.setItem('currentUserFullName', currentUserFullName);
    }, [currentUserFullName]);

    useEffect(() => {
        if (currentUserPhoneNum) localStorage.setItem('currentUserPhoneNum', currentUserPhoneNum);
    }, [currentUserPhoneNum]);

    useEffect(() => {
        if (currentUserEmail) localStorage.setItem('currentUserEmail', currentUserEmail);
    }, [currentUserEmail]);

    useEffect(() => {
        if (currentUserRole) localStorage.setItem('currentUserRole', currentUserRole);
    }, [currentUserRole]);

    const login = async (username, password, useTestDatabase) => {
        const pbInstance = createPocketBaseInstance(useTestDatabase);
        try {
            const data = await pbInstance.collection('users').authWithPassword(username, password);
            setCurrentUserId(data.record.id);
            localStorage.setItem('currentUserId', data.record.id);
            setCurrentUserFullName(data.record.name);
            localStorage.setItem('currentUserFullName', data.record.name);
            setCurrentUserPhoneNum(data.record.phonenum);
            localStorage.setItem('currentUserPhoneNum', data.record.phonenum);
            setCurrentUserEmail(data.record.email);
            localStorage.setItem('currentUserEmail', data.record.email);
            setCurrentUserRole(data.record.role);
            localStorage.setItem('currentUserRole', data.record.role);
            setIsTestDatabase(useTestDatabase);

            const authData = { token: pbInstance.authStore.token, record: data.record };
            localStorage.setItem('pb_auth', JSON.stringify(authData));

            setIsAuthenticated(pbInstance.authStore.isValid);
            setPb(pbInstance);
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = () => {
        pb.authStore.clear();
        localStorage.removeItem('currentUserId');
        localStorage.removeItem('currentUserFullName');
        localStorage.removeItem('currentUserPhoneNum');
        localStorage.removeItem('currentUserEmail');
        localStorage.removeItem('currentUserRole');
        localStorage.removeItem('isTestDatabase');
        localStorage.removeItem('pb_auth');
        setCurrentUserId(null);
        setCurrentUserFullName(null);
        setCurrentUserPhoneNum(null);
        setCurrentUserEmail(null);
        setCurrentUserRole(null);
        setIsAuthenticated(false);
        setIsTestDatabase(false);
        setPb(createPocketBaseInstance(false));
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, currentUserId, currentUserFullName, currentUserPhoneNum, currentUserEmail, currentUserRole, isTestDatabase, pb, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};