import React from 'react';
import TerenTable from '../../components/TerenTable/TerenTable';



const LandingPage = () => {
  return (
    <div>
      <TerenTable />
    </div>
  );
};

export default LandingPage;