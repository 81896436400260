function formatDateString(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}


// TODO: deduplicate prikljucki records in the database! - then remove this function
const deduplicatePrikljuckiRecords = (records) => {
  const uniqueRecords = new Map();

  records.forEach((record) => {
    // Create a unique key based on the fields
    const uniqueKey = `${record.opt_vrsta_prikljucka_id}-${record.opt_priklop_id}-${record.minimalna_zmogljivost}-${record.maticna}`;
    // If this key hasn't been seen before, add the record to the uniqueRecords
    if (!uniqueRecords.has(uniqueKey)) {
      uniqueRecords.set(uniqueKey, record);
    }
  });

  // Return only the unique values
  return Array.from(uniqueRecords.values());
};


const fetchDataTeren = async (pb, page, size, filters) => {
    try {
      let filterString = 'created >= "2022-01-01 00:00:00"';
  
      /*
      if (filters.opt_vrsta_subjekta_id.length > 0) {
        const opt_vrsta_subjekta_id_filter = filters.opt_vrsta_subjekta_id.map(id => `opt_vrsta_subjekta_id = ${id}`).join(' || ');
        filterString += ` && (${opt_vrsta_subjekta_id_filter})`;
      } */
      
      if (filters.vrsta_subjekta) {
        filterString += ` && vrsta_subjekta ~ "${filters.vrsta_subjekta}"`;
      }

      if (filters.obcina_naziv) {
        filterString += ` && obcina_naziv ~ "${filters.obcina_naziv}"`;
      }

      if (filters.naselje_naziv) {
        filterString += ` && naselje_naziv ~ "${filters.naselje_naziv}"`;
      }

      if (filters.ulica_naziv) {
        filterString += ` && ulica_naziv ~ "${filters.ulica_naziv}"`;
      }

      if (filters.hs_stevilka) {
        filterString += ` && hs_stevilka ~ "${filters.hs_stevilka}"`;
      }

      if (filters.postni_okolis_sifra) {
        filterString += ` && postni_okolis_sifra ~ "${filters.postni_okolis_sifra}"`;
      }

      if (filters.postni_okolis_naziv) {
        filterString += ` && postni_okolis_naziv ~ "${filters.postni_okolis_naziv}"`;
      }

      if (filters.st_stan_ali_dela) {
        filterString += ` && st_stan_ali_dela ~ "${filters.st_stan_ali_dela}"`;
      }

      let sortString = `naselje_naziv, ulica_naziv, hs_stevilka, st_stan_ali_dela`; // Fields to sort by
  
      const resultListOld = pb.collection('teren').getList(page, size, {
        filter: filterString,
        sort: sortString,
        expand: "prikljucki, obiski_via_teren, prikljucki.maticne_sifrant",
      });

      const resultList = await pb.send(`/naselje/${filters.naselje_naziv}`, {
        query: {
          prikljucek: "Bakrena parica", //TODO - hardcoded dokler ni filtra za priključek
          page,
          perPage: size,
          sort: sortString
        }
      });

      // const terenRecordsWithPrikljuckiAndObiski = await Promise.all(
      //   resultList.items.map(async terenRecord => {

      //     terenRecord.obiski = terenRecord.expand.obiski_via_teren;

      //     terenRecord.prikljucki = deduplicatePrikljuckiRecords(terenRecord.expand.prikljucki);

      //     for (let prikljuckiRecord of terenRecord.prikljucki ) {
      //       if (prikljuckiRecord.maticna) {
      //         //////////////////////////
      //         //const { ikona, naziv } = await fetchIcon(pb, prikljuckiRecord.maticna);
      //         const iconUrl = pb.files.getUrl(prikljuckiRecord.expand.maticne_sifrant, prikljuckiRecord.expand.maticne_sifrant.ikona /*, { token }*/);
      //         //prikljuckiRecord.icon = prikljuckiRecord.expand.maticne_sifrant.ikona;
      //         prikljuckiRecord.icon = iconUrl;
      //         prikljuckiRecord.naziv = prikljuckiRecord.expand.maticne_sifrant.naziv;
      //       }
      //     }

      //     let obiskiRecords = terenRecord.obiski;
      //     // Check if there are any obiski records and add the latest 'odziv' to the teren record
      //     if (obiskiRecords !== undefined && Array.isArray(obiskiRecords) && obiskiRecords.length > 0) {
      //       // Assuming you want the last odziv from the obiski records
      //       const latestObiskiRecord = obiskiRecords[obiskiRecords.length - 1];
      //       terenRecord.odziv = latestObiskiRecord.odziv;
      //       terenRecord.opombe = latestObiskiRecord.opombe;
      //       terenRecord.datum = formatDateString(latestObiskiRecord.datum);
      //     } else {
      //       terenRecord.odziv = null; // or some default value
      //       terenRecord.opombe = null; // or some default value
      //       terenRecord.datum = null; // or some default value
      //     }
      //     return terenRecord;

      //   })
      // );

      
      // print the teren records with prikljucki records
      //console.log('terenRecordsWithPrikljucki', terenRecordsWithPrikljucki);

      return {
        data: resultList.items,
        // data: terenRecordsWithPrikljuckiAndObiski,// new data with prikljucki records
        currentPage: resultList.page,
        totalItems: resultList.totalItems,
      };
  
    } catch (err) {
      console.error('Error fetching data:', err);
      return {
        data: [],
        currentPage: 1,
        totalItems: 0,
      };
    }
  };
  
  export default fetchDataTeren;
  