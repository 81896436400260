
  const fetchDataPlans = async (pb, userId) => {
    try {
      // fetch a paginated records list
    const resultList = await pb.collection('plan').getList(1, 5000, {
      filter: `agent = "${userId}"`,
    });

    console.log("filter", `agent = "${userId}"`);
    console.log('Fetched plans:', resultList); // Added this line

    return {
      data: resultList.items,
    };
  
    } catch (err) {
      console.error('Error fetching data:', err);
      return {
        data: [],
      };
    }
  };
  
  export default fetchDataPlans;
  