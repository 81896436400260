const fetchDataPackages = async (pb, packageType) => {
    try {
      // fetch a paginated records list
      // filter by package type
      if (!packageType) {
        // fetch all records if packageType is not provided
        const resultList = await pb.collection('paketi').getList(1, 500, {
          filter: '',
        });
        return {
          data: resultList.items,
        };
      }
    const resultList = await pb.collection('paketi').getList(1, 500, {
      filter: `tip = "${packageType}"`,
    });

    return {
      data: resultList.items,
    };
  
    } catch (err) {
      console.error('Error fetching data:', err);
      return {
        data: [],
      };
    }
  };
  
  export default fetchDataPackages;
  