import React, { useRef, useState, useEffect } from 'react';

import SupersetDashboard from '../../components/SupersetDashboard/SupersetDashboard';
import AgentDashboard from './AgentDashboard';

const AnalyticsPage = () => {
  return (
    <div>
      <AgentDashboard />
    </div>
  );
};

// Export the component
export default AnalyticsPage;