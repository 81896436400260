import React from 'react';
import { MultiSelect } from '@carbon/react'; // Assuming you're using Carbon components

const ColumnSelector = ({ headers, selectedColumns, setSelectedColumns }) => {
  const initialSelectedItems = headers.filter(header => selectedColumns[header.key]);

  return (
    <MultiSelect
      id="column-selector"
      label="Prikaži"
      items={headers}
      itemToString={item => (item ? item.header : '')}
      initialSelectedItems={initialSelectedItems}
      compareItems={() => 0} // Custom comparison function to prevent sorting
      onChange={({ selectedItems }) => {
        const newSelectedColumns = selectedItems.reduce((acc, item) => {
          acc[item.key] = true;
          return acc;
        }, {});
        setSelectedColumns(newSelectedColumns);
      }}
    />
  );
};

export default ColumnSelector;
