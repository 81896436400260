import React, { useEffect, useState } from 'react';
import { DonutChart, GaugeChart, ComboChart } from "@carbon/charts-react";
import PocketBase from 'pocketbase';
import { ProgressBar, Loading, DataTable, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, TableContainer, TableToolbar, TableToolbarSearch, TableToolbarContent, TableSelectAll, TableSelectRow, Tile, Accordion, AccordionItem } from '@carbon/react';
import "@carbon/charts/styles.css";
import "./AgentDashboard.css";
import TerrainsSummary from './TerrainsSummary';
import { useAuth } from '../../AuthContext';

const AgentDashboard = () => {

  const { pb, currentUserId, isAuthenticated } = useAuth();

  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [naCount, setNaCount] = useState(0);
  const [naPercentage, setNaPercentage] = useState(0);
  const [odzivCounts, setOdzivCounts] = useState({});
  const [odzivPercentages, setOdzivPercentages] = useState({});
  const [donutData, setDonutData] = useState([]);
  const [salesCount, setSalesCount] = useState(0);
  const [totalSalesCount, setTotalSalesCount] = useState(0);
  const [donutOptions, setDonutOptions] = useState({
    title: "Porazdelitev odzivov ob obiskih",
    resizable: true,
    donut: {
      center: {
        label: "Prodaje"
      }
    },
    height: "300px"
  });
  const [gaugeData, setGaugeData] = useState([]);
  const [gaugeOptions, setGaugeOptions] = useState({
    title: "Učinkovitost agenta v prodajnem procesu",
    resizable: true,
    height: "250px",
    width: "100%",
    gauge: {
      type: "semi",
      status: "danger"
    }
  });
  const [comboData, setComboData] = useState([]);
  const [comboOptions, setComboOptions] = useState({
    title: "Obiski in prodaje skozi čas",
    points: {
      enabled: true
    },
    axes: {
      left: {
        title: "Obiski",
        mapsTo: "value"
      },
      bottom: {
        scaleType: "time",
        mapsTo: "key",
        time: {
          unit: "day"
        }
      }
    },
    comboChartTypes: [
      {
        type: "area",
        options: {},
        correspondingDatasets: [
          "Obiski"
        ]
      },
      {
        type: "line",
        options: {
          points: {
            enabled: true
          }
        },
        correspondingDatasets: [
          "Prodaje"
        ]
      }
    ],
    curve: "curveNatural",
    timeScale: {
      addSpaceOnEdges: 0
    },
    height: "400px"
  });

  useEffect(() => {
    const fetchVisitsAndClients = async () => {
      if (isAuthenticated) {
        try {
          const userId = currentUserId;
  
          const userVisits = await pb.collection('obiski').getFullList({
            filter: `agent='${userId}'`, 
            sort: '-datum',
          });
  
          // Fetch all clients
          const clients = await pb.collection('stranke').getFullList();
  
          const matchedVisits = userVisits
            .filter(visit => visit.odziv === 'Prodano' || visit.odziv === 'DG') 
            .map(visit => {
              const matchedClient = clients.find(client => client.teren === visit.teren) || {};
              return {
                ...visit,
                clientName: matchedClient.ime ? `${matchedClient.ime} ${matchedClient.priimek || ''}` : 'N/A', 
                clientAddress: matchedClient.ulica_naziv ? `${matchedClient.ulica_naziv} ${matchedClient.hs_stevilka || ''}, ${matchedClient.postni_okolis_naziv || ''}` : 'N/A',
                clientContact: matchedClient.kontakt_tel ? `${matchedClient.kontakt_tel}, ${matchedClient.email || 'N/A'}` : 'N/A',
              };
            });
  
          setVisits(matchedVisits);
  
          calculateOdzivStats(userVisits);
  
          const allVisits = await pb.collection('obiski').getFullList();
          calculateTotalSales(allVisits, userVisits);
          prepareComboChartData(userVisits);
        } catch (error) {
          console.error('Error fetching visits and clients:', error);
        }
      } else {
        console.error('No user authenticated');
      }
      setLoading(false);
    };
  
    fetchVisitsAndClients();
  }, []);

  const calculateOdzivStats = (records) => {
    const counts = {};
    let naCountTemp = 0;
    let salesCountTemp = 0;

    records.forEach(visit => {
      const odziv = visit.odziv;
      if (odziv === '') {
        naCountTemp++;
      }
      if (odziv === 'Prodano') {
        salesCountTemp++;
      }
      if (counts[odziv]) {
        counts[odziv]++;
      } else {
        counts[odziv] = 1;
      }
    });

    const percentages = {};
    Object.keys(counts).forEach(odziv => {
      percentages[odziv] = (counts[odziv] / records.length) * 100;
    });

    setNaCount(naCountTemp);
    setNaPercentage((naCountTemp / records.length) * 100);
    setOdzivCounts(counts);
    setOdzivPercentages(percentages);
    setSalesCount(salesCountTemp);

    const donutChartData = Object.keys(counts).map(odziv => ({
      group: odziv,
      value: counts[odziv]
    }));

    setDonutData(donutChartData);
    setDonutOptions(prevOptions => ({
      ...prevOptions,
      donut: {
        center: {
          label: salesCountTemp.toString() + " Prodano"
        }
      }
    }));
  };

  const groupByDays = (records, filterFn) => {
    const groupedData = {};
    records.filter(filterFn).forEach(record => {
      const date = new Date(record.datum);
      const day = date.toISOString().split('T')[0];
      if (!groupedData[day]) {
        groupedData[day] = 0;
      }
      groupedData[day] += 1;
    });
    return groupedData;
  };

  const fillMissingDates = (data, startDate, endDate) => {
    const filledData = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dateStr = currentDate.toISOString().split('T')[0];
      const existingData = data.find(d => d.key.toISOString().split('T')[0] === dateStr);

      if (existingData) {
        filledData.push(existingData);
      } else {
        filledData.push({
          key: new Date(dateStr),
          value: 0,
          group: data[0].group
        });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return filledData;
  };

  const calculateTotalSales = (allRecords, userRecords) => {
    let totalSales = 0;
    allRecords.forEach(record => {
      if (record.odziv === 'Prodano') {
        totalSales++;
      }
    });
    setTotalSalesCount(totalSales);

    if (totalSales > 0) {
      const salesProportion = (salesCount / totalSales) * 100;
      setGaugeData([
        {
          group: "value",
          value: salesProportion
        }
      ]);
    }
  };

  useEffect(() => {
    const updateGaugeData = () => {
      if (totalSalesCount > 0) {
        const salesProportion = (salesCount / totalSalesCount) * 100;
        setGaugeData([
          {
            group: "value",
            value: salesProportion
          }
        ]);
      }
    };
    updateGaugeData();
  }, [totalSalesCount, salesCount]);

  const prepareComboChartData = (userRecords) => {
    const userVisitsGrouped = groupByDays(userRecords, () => true);
    const userSalesGrouped = groupByDays(userRecords, record => record.odziv === 'Prodano');

    const userVisitsData = Object.keys(userVisitsGrouped).map(day => ({
      group: "Obiski",
      key: new Date(day),
      value: userVisitsGrouped[day]
    }));

    const userSalesData = Object.keys(userSalesGrouped).map(day => ({
      group: "Prodaje",
      key: new Date(day),
      value: userSalesGrouped[day]
    }));

    const allDates = Object.keys(userVisitsGrouped).concat(Object.keys(userSalesGrouped)).map(day => new Date(day));
    const startDate = new Date(Math.min(...allDates));
    const endDate = new Date(Math.max(...allDates));

    const filledUserVisitsData = fillMissingDates(userVisitsData, startDate, endDate);
    const filledUserSalesData = fillMissingDates(userSalesData, startDate, endDate);

    const sortedData = [...filledUserVisitsData, ...filledUserSalesData].sort((a, b) => new Date(a.key) - new Date(b.key));

    setComboData(sortedData);
  };


  const completedVisits = visits.length - naCount;
  const progressPercentage = (completedVisits / visits.length) * 100;

  const recentVisitsHeaders = [
    { key: 'date', header: 'Datum' },
    { key: 'customer', header: 'Stranka' },
    { key: 'address', header: 'Naslov' },
    { key: 'contact', header: 'Kontakt' },
    { key: 'odziv', header: 'Odziv' },
    { key: 'notes', header: 'Opombe' }
  ];

  const recentVisitsRows = visits.slice(0, 5).map((visit) => ({
    id: visit.id,
    date: new Date(visit.datum).toLocaleDateString(),
    customer: visit.clientName, 
    address: visit.clientAddress,
    contact: visit.clientContact,
    odziv: visit.odziv,
    notes: visit.opombe
  }));

  return (
    <div className="dashboard">
      {loading ? (
        <Loading description="Nalaganje obiskov..." withOverlay={true} />
      ) : (
        <div className="dashboard-content">
          <div className="dashboard-header">
            <h1>Nadzorna plošča</h1>
            <h2>Skupna prodaja: {totalSalesCount}</h2>
          </div>
          <div className="dashboard-section">
            <h3>Dodeljeni obiski</h3>
            <div className="dashboard-metrics">
              <div className="metric">
                <TerrainsSummary />
              </div>
            </div>
          </div>
          <div className="dashboard-charts">
            <div className="dashboard-chart">
              <h3>Analiza odzivov strank</h3>
              <Tile>
                <Accordion>
                  {Object.keys(odzivCounts).map(odziv => (
                    <AccordionItem key={odziv} title={odziv}>
                      <p><strong>Število:</strong> {odzivCounts[odziv]}</p>
                      <p><strong>Odstotek:</strong> {odzivPercentages[odziv].toFixed(2)}%</p>
                    </AccordionItem>
                  ))}
                </Accordion>
              </Tile>
            </div>
            <div className="dashboard-chart">
              <h3>Vizualizacija odzivov</h3>
              <DonutChart
                data={donutData}
                options={donutOptions}>
              </DonutChart>
            </div>
            <div className="dashboard-chart">
              <h3>Delež prodaje agenta</h3>
              <GaugeChart
                data={gaugeData}
                options={gaugeOptions}>
              </GaugeChart>
            </div>
            <div className="dashboard-chart">
              <h3>Časovni pregled obiskov in prodaj</h3>
              <ComboChart
                data={comboData}
                options={comboOptions}>
              </ComboChart>
            </div>
          </div>
          <div className="dashboard-section">
            <h3>Nedavni obiski</h3>
            <DataTable rows={recentVisitsRows} headers={recentVisitsHeaders}>
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                getSelectionProps,
                onInputChange,
                selectedRows,
              }) => (
                <TableContainer
                  title="Nedavni obiski"
                  description="Podrobnosti o najnovejših obiskih"
                  {...getTableContainerProps()}
                >
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch onChange={onInputChange} />
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        <TableSelectAll {...getSelectionProps()} />
                        {headers.map((header) => (
                          <TableHeader
                            key={header.key}
                            {...getHeaderProps({ header })}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id} {...getRowProps({ row })}>
                          <TableSelectRow {...getSelectionProps({ row })} />
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>{cell.value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentDashboard;