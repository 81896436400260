import React from 'react';
import { Tooltip } from '@carbon/react';
import './_teren-table.scss'; // Import the SCSS file

// PrikljuckiIconComponent takes a prikljucki record as props
const PrikljuckiIconComponent = ({ prikljucki }) => {

  //console.log("---------prikljucki", prikljucki);

  const { icon, minimalna_zmogljivost, naziv, vrsta_prikljucka, priklop } = prikljucki;
  const altText = `${prikljucki.maticna}`;

    // Format the content for the tooltip
    const tooltipContent = (
      <div>
        <p><strong>{naziv}</strong></p>
        <p>{vrsta_prikljucka}, {minimalna_zmogljivost} Mb/s</p>
        <p>Priklop: {priklop}</p>
      </div>
    );


  return (
    //<div style={{ position: 'relative', zIndex: 9999999 }}>
      <Tooltip align="bottom-right" label={tooltipContent} enterDelayMs={0} leaveDelayMs={0}>
        <button className="prikljucki-icon" type="button" aria-label="More info"> {/* Use button as trigger */}
          <img src={icon} alt={altText} />
          <span className="prikljucki-number">{minimalna_zmogljivost}</span>
        </button>
      </Tooltip>
    //</div>
  );
};

export default PrikljuckiIconComponent;
