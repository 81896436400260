/**
 * Fetches data for popusti.
 * @param {object} pb - The pb object.
 * @param {string} packageId - The package ID.
 * @returns {Promise<object>} - The fetched data.
 */
const fetchDataPopusti = async (pb, packageId) => {
  try {
    let resultList;

    if (!packageId) {
      // fetch all records if packageId is not provided
      resultList = await pb.collection('paketi_akcije').getList(1, 500, {
        filter: '',
      });
    } else {
      // fetch records filtered by packageId
      resultList = await pb.collection('paketi_akcije').getList(1, 500, {
        filter: `paket = "${packageId}"`,
      });
    }

    return {
      data: resultList.items,
    };

  } catch (err) {
    console.error('Error fetching data:', err);
    return {
      data: [],
    };
  }
};

export default fetchDataPopusti;
