

const generateCryptoRandomString = async() => {
  const byteSize = 10; // Adjust byteSize to increase or decrease the resulting string length
  const randomBytes = new Uint8Array(byteSize);
  window.crypto.getRandomValues(randomBytes);

  // Convert the random bytes to a base-36 string and remove the '0.' prefix added by toString(36)
  const randomString = Array.from(randomBytes)
    .map((byte) => byte.toString(36).padStart(2, '0'))
    .join('')
    .replace(/[^a-z0-9]/gi, ''); // Ensure only alphanumeric characters

  // Return the string, trimmed or padded to ensure it's approximately 15 characters long
  return randomString.substring(0, 15);
}


export default generateCryptoRandomString;


