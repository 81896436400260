import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import { Accordion, AccordionItem, Loading, Button, TextInput } from 'carbon-components-react';
import { useAuth } from '../../AuthContext';

const OrdersList = () => {

  const { pb, currentUserId, isAuthenticated } = useAuth();


  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const result = await pb.collection('narocilo').getFullList({
        sort: '-created',
      });
      setOrders(result);
      setFilteredOrders(result); 
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = orders.filter((order) => {
      return Object.values(order).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchValue)
      );
    });
    setFilteredOrders(filtered);
  };

  return (
    <div className="orders-list-container">
      <h1>Seznam naročil</h1>

      <TextInput
        id="order-search"
        labelText="Iskanje naročil"
        placeholder="Išči po ID-ju naročila, imenu ali e-pošti..."
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px' }}
      />

      {loading ? (
        <Loading description="Nalagam naročila..." withOverlay={false} />
      ) : (
        <Accordion>
          {filteredOrders.map((order) => (
            <AccordionItem
              key={order.id}
              title={`${order.id} - ${order.ime} ${order.priimek} - ${order.ulica} ${order.hisna_st}, ${order.posta} - ${new Date(order.created).toLocaleDateString('sl-SI')}`}
            >
              <Accordion>
                <AccordionItem title="Naročnik (Informacije o naročniku)">
                  <p><strong>Ime:</strong> {order.ime}</p>
                  <p><strong>Priimek:</strong> {order.priimek}</p>
                  <p><strong>Zakoniti zastopnik družbe:</strong> {order.zakonitiZastopnik || 'Ni na voljo'}</p>
                  <p><strong>Matična številka:</strong> {order.maticna_st_podjetje || 'Ni na voljo'}</p>
                  <p><strong>Zavezanec za DDV:</strong> {order.zavezanec_za_ddv ? 'Da' : 'Ne'}</p>
                  <p><strong>Ulica, hišna številka:</strong> {order.ulica}, {order.hisna_st}</p>
                  <p><strong>Poštna številka:</strong> {order.postna_st}</p>
                  <p><strong>Pošta:</strong> {order.posta}</p>
                  <p><strong>Nadstropje in št. stanovanja:</strong> {order.nadstropje_in_st_stanovanja || 'Ni na voljo'}</p>
                  <p><strong>Davčna številka:</strong> {order.davcna_st || 'Ni na voljo'}</p>
                  <p><strong>Kontaktni telefon:</strong> {order.kontakt_tel || 'Ni na voljo'}</p>
                  <p><strong>E-poštni naslov:</strong> {order.email}</p>
                  <p><strong>Opombe:</strong> {order.opombe || 'Ni na voljo'}</p>
                </AccordionItem>

                <AccordionItem title="Priključek in storitve">
                  <p><strong>Tip paketa:</strong> {order.mobilni_paket ? 'Mobilni paket' : 'Fiksni paket'}</p>
                  <p><strong>Zahtevek za prenos številke:</strong> {order.prenos_telefonske ? 'Da' : 'Ne'}</p>
                  <p><strong>Tehnologija:</strong> {order.tehnologija || 'Ni na voljo'}</p>
                  <p><strong>Paket storitev:</strong> {order.paketStoritev || 'Ni na voljo'}</p>
                  <p><strong>Akcija:</strong> {order.akcija_rel || 'Ni na voljo'}</p>
                  <p><strong>Dodatni paketi in oprema:</strong> {order.dodatne_storitve_interneta || 'Ni na voljo'}</p>
                </AccordionItem>

                <AccordionItem title="Prenos številke">
                  <p><strong>Telefonska številka za prenos:</strong> {order.prenos_telefonska_st || 'Ni na voljo'}</p>
                  <p><strong>Operater (darovalec):</strong> {order.operater_donor || 'Ni na voljo'}</p>
                  <p><strong>Številka računa pri operaterju:</strong> {order.st_racuna_donorja_ali_puk || 'Ni na voljo'}</p>
                  <p><strong>Naročnik je druga oseba:</strong> {order.narocnik ? 'Da' : 'Ne'}</p>
                  {order.narocnik && (
                    <>
                      <p><strong>Ime in priimek/naziv podjetja:</strong> {order.prenos_ime || 'Ni na voljo'}</p>
                      <p><strong>Naslov stalnega bivališča/sedež podjetja:</strong> {order.prenos_naslov || 'Ni na voljo'}</p>
                      <p><strong>Poštna št.:</strong> {order.prenos_postna_kraj || 'Ni na voljo'}</p>
                      <p><strong>Kraj:</strong> {order.prenos_kraj || 'Ni na voljo'}</p>
                      <p><strong>Davčna številka:</strong> {order.prenos_davcna || 'Ni na voljo'}</p>
                    </>
                  )}
                </AccordionItem>

                <AccordionItem title="Računi">
                  <p><strong>E-poštni račun:</strong> {order.racun_email ? 'Da' : 'Ne'}</p>
                  {order.racun_email && (
                    <p><strong>E-poštni naslov za račun:</strong> {order.racun_email_naslov || 'Ni na voljo'}</p>
                  )}
                  <p><strong>SMS račun:</strong> {order.racun_sms ? 'Da' : 'Ne'}</p>
                  <p><strong>Račun po pošti:</strong> {order.racun_pisno ? 'Da' : 'Ne'}</p>
                  {order.racun_pisno && (
                    <>
                      <p><strong>Ulica, hišna številka:</strong> {order.racun_pisno_ulica}, {order.racun_pisno_mesto}</p>
                      <p><strong>Poštna št.:</strong> {order.racun_pisno_postna_st || 'Ni na voljo'}</p>
                      <p><strong>Mesto:</strong> {order.racun_pisno_mesto || 'Ni na voljo'}</p>
                    </>
                  )}
                </AccordionItem>

                <AccordionItem title="Priloge">
                  {order.priloge && order.priloge.length > 0 ? (
                    order.priloge.map((attachment, index) => (
                      <p key={index}><strong>Dokument:</strong> {attachment}</p>
                    ))
                  ) : (
                    <p>Ni priloženih dokumentov</p>
                  )}
                </AccordionItem>

                <AccordionItem title="GDPR (Splošna uredba o varstvu podatkov)">
                  <p><strong>Soglasje za obdelavo osebnih podatkov:</strong> {order.gdpr_consent ? 'Da' : 'Ne'}</p>
                  <p><strong>Soglasje za prejemanje marketinških sporočil:</strong> {order.gdpr_marketing ? 'Da' : 'Ne'}</p>
                </AccordionItem>

              </Accordion>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default OrdersList;