import React from 'react';
import { CheckboxGroup, Checkbox, TextInput, Button } from '@carbon/react';

const FilterForm = ({ filters, setFilters, applyFilters }) => {
  return (
    <div>
          <div>
            <TextInput
              id="obcina_naziv"
              labelText="Občina"
              value={filters.obcina_naziv}
              onChange={(e) => setFilters({ ...filters, obcina_naziv: e.target.value })}
            />
          </div>
          <div>
            <TextInput
              id="naselje_naziv"
              labelText="Naselje"
              value={filters.naselje_naziv}
              onChange={(e) => setFilters({ ...filters, naselje_naziv: e.target.value })}
            />
          </div>
          <div>
            <TextInput
              id="ulica_naziv"
              labelText="Ulica"
              value={filters.ulica_naziv}
              onChange={(e) => setFilters({ ...filters, ulica_naziv: e.target.value })}
            />
          </div>
          <div>
            <TextInput
              id="hs_stevilka"
              labelText="Hišna št."
              value={filters.hs_stevilka}
              onChange={(e) => setFilters({ ...filters, hs_stevilka: e.target.value })}
            />
          </div>
          <div>
            <TextInput
              id="postni_okolis_sifra"
              labelText="Poštna št."
              value={filters.postni_okolis_sifra}
              onChange={(e) => setFilters({ ...filters, postni_okolis_sifra: e.target.value })}
            />
          </div>
          <div>
            <TextInput
              id="postni_okolis_naziv"
              labelText="Pošta"
              value={filters.postni_okolis_naziv}
              onChange={(e) => setFilters({ ...filters, postni_okolis_naziv: e.target.value })}
            />
          </div>
          <div>
            <TextInput
              id="st_stan_ali_dela"
              labelText="Št. stan./dela"
              value={filters.st_stan_ali_dela}
              onChange={(e) => setFilters({ ...filters, st_stan_ali_dela: e.target.value })}
            />
          </div>
          <div>
            {/*}
            <CheckboxGroup
              legendText="Vrsta Subjekta ID">
              <Checkbox
                labelText="1"
                id="opt_vrsta_subjekta_id-1"
                checked={filters.opt_vrsta_subjekta_id.includes("1")}
                onChange={() => setFilters(prev => ({...prev, opt_vrsta_subjekta_id: prev.opt_vrsta_subjekta_id.includes("1") ? prev.opt_vrsta_subjekta_id.filter(x => x !== "1") : [...prev.opt_vrsta_subjekta_id, "1"] }))}
              />
              <Checkbox
                labelText="2"
                id="opt_vrsta_subjekta_id-2"
                checked={filters.opt_vrsta_subjekta_id.includes("2")}
                onChange={() => setFilters(prev => ({...prev, opt_vrsta_subjekta_id: prev.opt_vrsta_subjekta_id.includes("2") ? prev.opt_vrsta_subjekta_id.filter(x => x !== "2") : [...prev.opt_vrsta_subjekta_id, "2"] }))}
              />
              <Checkbox
                labelText="3"
                id="opt_vrsta_subjekta_id-3"
                checked={filters.opt_vrsta_subjekta_id.includes("3")}
                onChange={() => setFilters(prev => ({...prev, opt_vrsta_subjekta_id: prev.opt_vrsta_subjekta_id.includes("3") ? prev.opt_vrsta_subjekta_id.filter(x => x !== "3") : [...prev.opt_vrsta_subjekta_id, "3"] }))}
              />
            </CheckboxGroup>
            */}
            <TextInput
              id="vrsta_subjekta"
              labelText="Vrsta subjekta"
              value={filters.vrsta_subjekta}
              onChange={(e) => setFilters({ ...filters, vrsta_subjekta: e.target.value })}
            />
          </div>
          <br />
          <Button onClick={applyFilters}>
            Uveljavi filtre
          </Button>
    </div>
  );
};

export default FilterForm;
