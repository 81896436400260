"use strict";
exports.__esModule = true;
exports.BaseIntentRecognizer = void 0;
var BaseIntentRecognizer = /** @class */ (function () {
    function BaseIntentRecognizer() {
        this.intents = {};
    }
    BaseIntentRecognizer.prototype.generatePattern = function (currentIntent) {
        var _this = this;
        var allKeywords = Object.entries(this.intents)
            .flatMap(function (_a) {
            var def = _a[1];
            return def.keywords;
        });
        var keywordPattern = allKeywords
            .map(function (kw) { return "\\b".concat(_this.escapeRegExp(kw), "\\b"); })
            .join('|');
        return new RegExp("(?:je\\s+)?(.+?)(?=\\s+(".concat(keywordPattern, ")|$)"), 'i');
    };
    BaseIntentRecognizer.prototype.recognize = function (input) {
        var _a;
        //console.log('Starting recognition for input:', input);
        var allMatches = {};
        var remainingInput = ' ' + input.toLowerCase() + ' '; // Add spaces at the beginning and end
        var iterationCount = 0;
        var maxIterations = 400000; // Safeguard against infinite loops
        while (remainingInput.trim().length > 0 && iterationCount < maxIterations) {
            //console.log(`Iteration ${iterationCount + 1}, Remaining input:`, remainingInput);
            var bestMatch = null;
            for (var _i = 0, _b = Object.entries(this.intents); _i < _b.length; _i++) {
                var _c = _b[_i], intent = _c[0], definition = _c[1];
                //console.log(`Checking intent: ${intent}`);
                var sortedKeywords = definition.keywords.sort(function (a, b) { return b.length - a.length; });
                for (var _d = 0, sortedKeywords_1 = sortedKeywords; _d < sortedKeywords_1.length; _d++) {
                    var keyword = sortedKeywords_1[_d];
                    var keywordIndex = remainingInput.indexOf(" ".concat(keyword, " "));
                    if (keywordIndex !== -1) {
                        //console.log(`Found keyword "${keyword}" for intent "${intent}" at index ${keywordIndex}`);
                        if (definition.isPresenceBased) {
                            bestMatch = { intent: intent, keyword: keyword, index: keywordIndex };
                            //console.log(`Presence-based match found for intent "${intent}"`);
                            break;
                        }
                        else if (definition.valueExtractor) {
                            var afterKeyword = remainingInput.slice(keywordIndex + keyword.length + 1);
                            var pattern = this.generatePattern(intent);
                            var match = afterKeyword.match(pattern);
                            if (match) {
                                var rawValue = match[1].trim();
                                var value = definition.valueExtractor(rawValue);
                                if (value) {
                                    bestMatch = { intent: intent, keyword: keyword, index: keywordIndex, value: value };
                                    //console.log(`Value-based match found for intent "${intent}": ${value}`);
                                    break;
                                }
                            }
                        }
                    }
                }
                if (bestMatch)
                    break; // Exit the intent loop if a match is found
            }
            if (bestMatch) {
                if (!allMatches[bestMatch.intent]) {
                    allMatches[bestMatch.intent] = [];
                }
                allMatches[bestMatch.intent].push({
                    intent: bestMatch.intent,
                    value: bestMatch.value || 'true'
                });
                var matchedPart = remainingInput.slice(0, bestMatch.index + bestMatch.keyword.length + (((_a = bestMatch.value) === null || _a === void 0 ? void 0 : _a.length) || 0) + 2);
                remainingInput = remainingInput.replace(matchedPart, ' ');
                //console.log(`Matched part removed: "${matchedPart.trim()}"`);
                //console.log(`Updated remaining input: "${remainingInput.trim()}"`);
            }
            else {
                // If no new intent is recognized, remove the first word and continue
                //const firstSpace = remainingInput.indexOf(' ', 1);
                //const removedWord = remainingInput.slice(0, firstSpace).trim();
                //remainingInput = remainingInput.slice(firstSpace).trim();
                remainingInput = remainingInput.slice(1).trim();
                //console.log(`No match found. Removed word: "${removedWord}"`);
                //console.log(`Updated remaining input: "${remainingInput}"`);
            }
            iterationCount++;
        }
        if (iterationCount >= maxIterations) {
            console.warn('Maximum iterations reached. Recognition stopped prematurely.');
        }
        //console.log('All matches:', allMatches);
        // Return only the last occurrence of each intent
        var result = Object.values(allMatches).map(function (matches) { return matches[matches.length - 1]; });
        //console.log('Final recognition result:', result);
        return result;
    };
    BaseIntentRecognizer.prototype.addIntent = function (intent, keywords, valueExtractor) {
        this.intents[intent] = { keywords: keywords, valueExtractor: valueExtractor, isPresenceBased: !valueExtractor };
    };
    BaseIntentRecognizer.prototype.escapeRegExp = function (string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };
    return BaseIntentRecognizer;
}());
exports.BaseIntentRecognizer = BaseIntentRecognizer;
