// createNarociloRecord.js

import { useState } from "react";
import validateTaxNumber from "../../content/SalePage/validateTaxNumber";
import generateCryptoRandomString from "../generateCryptoRandomString";

const updateAndExpandStranke = async (pb, strankaId, gdpr) => {
  // Step 1: Create the new record
  //const newRecord = await pb.collection('stranke').create(data);
  const newStrankaRecord = await pb.collection('stranke').update(strankaId, { gdpr: gdpr });

  // Step 2: Retrieve the created record with expansion
  const expandedRecord = await pb.collection('stranke').getOne(newStrankaRecord.id, { expand: 'gdpr' });

  return expandedRecord;
};

/**
 * Creates or updates an 'narocilo' record in the database.
 * Also creates or updates a 'stranka' record if the 'davcna_st' field is provided.
 * Also creates or updates a 'gdpr' record.
 * @param {Object} pb - The database instance or API handler.
 * @param {Object} recordData - The data for the new or existing 'obiski' record.
 * @param {string} obiskiId - The ID of the 'obiski' record.
 * @param {string} narociloId - The ID of the 'narocilo' record.
 * @returns {Promise<Object>} - The result of the record creation or update.
 */
const createOrUpdateNarociloRecord = async (pb, recordData, gdprConsent, gdprChanged, setGdprChanged, opremaData, obiskiId, narociloId) => {
  try {
    let result;

    recordData.obiski = obiskiId;

    // Check if stranka with id = narocilo.davcna_st exists
    const davcnaSt = recordData.davcna_st;
    let strankaRecord;
    let newStrankaRecord = null;
    let strankaId = null;
    let updatedStranka = null;

    let isTaxNumberValid = true;

  
    // Function to check if the tax number is valid
    const checkTaxNumber = (value) => {
      const isValid = validateTaxNumber(value);
      return isValid;
    };

    // Check if the tax number is valid
    isTaxNumberValid = checkTaxNumber(davcnaSt.toString());
    console.log('taxNumber:', davcnaSt.toString());
    console.log('isTaxNumberValid:', isTaxNumberValid);

    // only proceed if the tax number is valid - we don't want to create a record with an invalid tax number
    if (isTaxNumberValid) {
      try {
        const filter = `davcna_st="${davcnaSt}"`;
        const records = await pb.collection('stranke').getFullList({
          filter,
          limit: 1
        });

        if (records.length > 0) {
          strankaRecord = records[0];
        }
      } catch (err) {
        // If the record does not exist, handle it in the next step
        if (err.status !== 404) {
          throw err; // If error is not 404, rethrow it
        }
      }

      // Mapping narocilo fields to stranke fields
      const strankaData = {
        ime: recordData.ime,
        priimek: recordData.priimek,
        ulica_naziv: recordData.ulica,
        hs_stevilka: recordData.hisna_st,
        st_stan_ali_dela: recordData.postna_st,
        nadstropje: recordData.nadstropje_in_st_stanovanja,
        postni_okolis_naziv: recordData.posta,
        postni_okolis_sifra: recordData.postna_st,
        email: recordData.email,
        kontakt_tel: recordData.kontakt_tel,
        teren: recordData.teren,
        naselje: recordData.naselje,
        davcna_st: davcnaSt,
        podjetje: recordData.podjetje,
        zavezanec_za_ddv: recordData.zavezanec_za_ddv,
        maticna_st_podjetje: recordData.maticna_st_podjetje,
      };

      console.log('Stranka Data:', strankaData);
      if (strankaRecord) {
        strankaId = strankaRecord.id;
        // Check if the data matches
        let needsUpdate = false;
        for (const key in strankaData) {
          if (strankaData[key] !== strankaRecord[key]) {
            needsUpdate = true;
            break;
          }
        }

        if (needsUpdate) {
          // Update the stranka record if data does not match
          await pb.collection('stranke').update(strankaRecord.id, strankaData);
          console.log('Stranka record updated successfully:', strankaRecord.id);
        }

        console.log('GDPR Changed:', gdprChanged);
        if (gdprChanged) {
          const gdprData = {
            ...gdprConsent,
            stranka: strankaId,
            kraj: recordData.naselje,
            datum: new Date().toISOString().split('T')[0] // Current date in YYYY-MM-DD format
          };
          // check if gdpr record exists
          if (strankaRecord.gdpr) {
            await pb.collection('gdpr').update(strankaRecord.gdpr, gdprData);
            console.log('GDPR record updated successfully:', strankaRecord.gdpr);
            updatedStranka = await pb.collection('stranke').getOne(strankaRecord.id, { expand: 'gdpr' });
          } // no else needed, gdpr record createsd together with stranka record
          // in case logic changes, add else here

        }

      } else { // Create a new stranka record if it does not exist
        
        const newStrankaId = await generateCryptoRandomString(15); // Ensure ID is 15 characters long



        let newStrankaRecord1 = await pb.collection('stranke').create({ id: newStrankaId, ...strankaData });
        // if I had to create nw stranka record, I would have to reset the gdpr data
        const newGdprConsent ={
          cb_1_1: false, cb_1_2: false, cb_1_3: false, cb_1_4: false, cb_1_5: false, cb_1_6: false, cb_1_7: false,
          cb_2_1: false, cb_2_2: false, cb_2_3: false, cb_2_4: false, cb_2_5: false, cb_2_6: false, cb_2_7: false,
          cb_3_1: false, cb_3_2: false, cb_3_3: false, cb_3_4: false, cb_3_5: false, cb_3_6: false, cb_3_7: false, signed: false
        };
        //newStrankaRecord = await createAndExpandStranke(pb, { id: newStrankaId, ...strankaData });
        strankaId = newStrankaId;
        console.log('Stranka record created successfully:', newStrankaId);

        const gdprData = {
          ...newGdprConsent,
          stranka: strankaId,
          kraj: recordData.naselje,
          datum: new Date().toISOString().split('T')[0] // Current date in YYYY-MM-DD format
        };
        const gdprRecord = await pb.collection('gdpr').create(gdprData);
        newStrankaRecord = await updateAndExpandStranke(pb, newStrankaId, gdprRecord.id);
        //newStrankaRecord = await pb.collection('stranke').update(newStrankaId, { gdpr: gdprRecord.id });
        console.log('New stranka record created successfully:', newStrankaRecord.id);
        console.log('GDPR record created successfully:', gdprRecord.id);
      }

      recordData.stranka = strankaId;
    }

    if (narociloId && narociloId !== '') {
      result = await pb.collection('narocilo').update(narociloId, recordData);
      console.log('Record updated successfully:', result);
    } else {
      // If no ID is provided, create a new record

      // Check for Geolocation support
      if (navigator.geolocation) {
        // Promise wrapper for getCurrentPosition
        const getCurrentPosition = (options = {}) => {
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
          });
        };

        try {
          // Request current position
          const position = await getCurrentPosition();
          const { latitude, longitude } = position.coords;

          // Add geolocation data to recordData
          recordData.geolatitude = latitude;
          recordData.geolongitude = longitude;
        } catch (geoError) {
          console.warn('Geolocation permission denied or error occurred:', geoError);
          // Handle the error or use default values
          recordData.geolatitude = null;
          recordData.geolongitude = null;
        }
      } else {
        console.warn('Geolocation is not supported by your browser');
        recordData.geolatitude = null;
        recordData.geolongitude = null;
      }


      console.log('Create Naročilo Record data:', recordData);
      result = await pb.collection('narocilo').create(recordData);
      narociloId = result.id;
      console.log('Record created successfully:', result);
    }

    // Add this code snippet inside your try block, after the narocilo record creation or update

// print cuirrent timestamp
console.log('Current timestamp:', new Date().toISOString());
console.log('Oprema Data:', opremaData);

// Process each record in opremaData array
for (let i = opremaData.length - 1; i >= 0; i--) {
  const item = opremaData[i];
  const { id, paket, akcija, saveStatus, tip } = item;

  if (!saveStatus || saveStatus === '' || saveStatus === 'existing') {
    continue; // Do nothing if saveStatus is empty
  } else if (saveStatus === 'new') {
    // Create a new record
    const newRecordData = {
      id: id || await generateCryptoRandomString(),
      narocilo: narociloId,
      paket: paket,
      akcija: akcija,
      tip: tip
    };
    await pb.collection('narocilododatnipaketi').create(newRecordData);
    console.log('New additional package record created successfully:', newRecordData.id);
    // Update the saveStatus to empty string
    opremaData[i].saveStatus = '';
  } else if (saveStatus === 'updated') {
    // Update an existing record based on id
    const updateRecordData = {
      narocilo: narociloId,
      paket: paket,
      akcija: akcija,
      tip: tip
    };
    await pb.collection('narocilododatnipaketi').update(id, updateRecordData);
    console.log('Additional package record updated successfully:', id);
    // Update the saveStatus to empty string
    opremaData[i].saveStatus = '';
  } else if (saveStatus === 'deleted') {
    // Delete an existing record based on id
    await pb.collection('narocilododatnipaketi').delete(id);
    console.log('Additional package record deleted successfully:', id);
    // Remove the record from the array
    opremaData.splice(i, 1);
  }
}


    return {
      success: true,
      data: result,
      newStranka: newStrankaRecord, // Return the new stranka record if created
      updatedStranka: updatedStranka, // Return the updated stranka record if updated
    };

  } catch (err) {
    console.error('Error in record operation:', err);
    return {
      success: false,
      error: err,
    };
  }
};


export default createOrUpdateNarociloRecord;
