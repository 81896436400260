// src/contexts/PlanMapContext.js
import React, { createContext, useState, useContext } from 'react';

const PlanMapContext = createContext();

export const usePlanMapContext = () => useContext(PlanMapContext);

export const PlanMapProvider = ({ children }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentPagePlan, setCurrentPagePlan] = useState(1);
  const [pageSizePlan, setPageSizePlan] = useState(10);
  const [flattenedPlanData, setFlattenedPlanData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plans, setPlans] = useState([]);

  // On modal close, set the refetchPlans state to true
  // TODO: This is a workaround to force the table to refetch data
  // TODO: You should update the data offline
  const [refetchPlanData, setRefetchPlanData] = useState(false);

  const contextValue = {
    selectedPlan,
    setSelectedPlan,
    currentPagePlan,
    setCurrentPagePlan,
    pageSizePlan,
    setPageSizePlan,
    flattenedPlanData,
    setFlattenedPlanData,
    selectedRowData,
    setSelectedRowData,
    plans,
    setPlans,
    refetchPlanData,
    setRefetchPlanData,
    //isModalOpen,
    //setIsModalOpen
  };

  return (
    <PlanMapContext.Provider value={contextValue}>
      {children}
    </PlanMapContext.Provider>
  );
};
