// MapComponent.js
import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import proj4 from 'proj4';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import transformCoordinates from './transformCoordiantes';


// Define projections
//proj4.defs("EPSG:3794", "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=426.9,142.6,460.1,4.91,4.49,-12.42,17.1 +units=m +no_defs");
proj4.defs("EPSG:3794", "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");


const defaultIcon = L.icon({
    iconUrl: require('../../themes/icons/marker-icon2.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});


const MapComponent = ({ data }) => {

  return (
    <MapContainer
      center={[46.0569, 14.5058]}
      zoom={8}
      scrollWheelZoom={false}
      touchZoom={true}
      doubleClickZoom={true}
      zoomControl={true}
      dragging={true}
      style={{ height: "100%", width: "100%" } }>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data.map((row, index) => {
          if (!row.geometry) return null;  // Skip rendering if geometry is null or undefined
          return (
              <Marker key={index} position={transformCoordinates(row.geometry)} icon={defaultIcon} />
          );
      })}
    </MapContainer>
  );
};

export default MapComponent;
