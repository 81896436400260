import React, { useRef, useState, useEffect } from 'react';
import fetchDataPlans from '../../components/TerenTable/fetchDataPlans';
import fetchDataPlanTeren from '../../components/TerenTable/fetchDataPlanTeren';
import MapComponent from '../../components/TerenTable/MapComponent';
import { ComboBox, Modal, TextInput } from '@carbon/react';
import TableComponent from '../../components/TerenTable/TableComponent';
import { useAuth } from '../../AuthContext';
import { headers, defaultColumnSelections } from '../../components/headers';
import { mapStyleHorizontal } from '../../components/TerenTable/styles';
import { Outlet } from 'react-router-dom';
import { usePlanMapContext } from '../../contexts/PlanMapContext';

/**
 * PlanMapApp component that displays a table of flattened plan data and a dropdown to select a plan.
 * @returns {JSX.Element} JSX element containing the PlanMapApp component
 */
const PlanMapApp = () => {
  // Authentication and user states
  const { isAuthenticated, currentUserId, pb, login, logout } = useAuth();

  const {
    selectedPlan, setSelectedPlan,
    currentPagePlan, setCurrentPagePlan,
    pageSizePlan, setPageSizePlan,
    flattenedPlanData, setFlattenedPlanData,
    selectedRowData, setSelectedRowData,
    plans, setPlans,
    refetchPlanData, setRefetchPlanData
 //   isModalOpen, setIsModalOpen
  } = usePlanMapContext();


// Add this state variable for the search term
const [searchTerm, setSearchTerm] = useState("");

// Add this function to handle search input changes
const handleSearchChange = (event) => {
  setSearchTerm(event.target.value);
};

const filterDataBySearchTerm = (data, searchTerm) => {
  const searchWords = searchTerm.toLowerCase().split(' ').filter(word => word.length > 0);

  return data.filter(item =>
    searchWords.every(word =>
      Object.values(item).some(value =>
        value !== null && value !== undefined && value.toString().toLowerCase().includes(word)
      )
    )
  );
};

const filteredData = filterDataBySearchTerm(flattenedPlanData, searchTerm);

  // print the context values
  console.log("--- PlanMapApp ---");
  console.log("selectedPlan", selectedPlan);
  console.log("currentPagePlan", currentPagePlan);
  console.log("pageSizePlan", pageSizePlan);
  console.log("flattenedPlanData", flattenedPlanData);
  console.log("selectedRowData", selectedRowData);
  //console.log("isModalOpen", isModalOpen);


  // State variables related to plans and selected plan
 // const [plans, setPlans] = useState([]);
  //const [selectedPlan, setSelectedPlan] = useState(null);

  // State variables related to table data and pagination
 // const [flattenedPlanData, setFlattenedPlanData] = useState([]);
 // const [currentPagePlan, setCurrentPagePlan] = useState(1);
  const [totalItemsPlan, setTotalItemsPlan] = useState(0); // Total number of items
 // const [pageSizePlan, setPageSizePlan] = useState(10); // Number of items per page
  //const [shouldFetchDataPlan, setShouldFetchDataPlan] = useState(false); // this is just a trick to force rerender

  // State variables related to modals and selected row data
  const [isModalOpen, setIsModalOpen] = useState(false); // state to control modal visibility
 // const [selectedRowData, setSelectedRowData] = useState(null); // state to store data of selected row

  // Handles pagination change in the plan table
  const handlePaginationChangePlan = ({ page, pageSize }) => {
    console.log("Changing page to:", page, "and page size to:", pageSize);
    // Update page and page size, and trigger data fetch
    setCurrentPagePlan(page);
    setPageSizePlan(pageSize);
    //setShouldFetchDataPlan(true);  // Enable data fetching when pagination changes
  };

  const handlePlanSelection = (selectedItem) => {
    setSelectedPlan(selectedItem);
    if (selectedItem) {
      localStorage.setItem('selectedPlanId', selectedItem.id);
    }
  };

  // Reset pagination when selected plan changes
  useEffect(() => {
    // Only reset the current page if the selected plan has actually changed
    const savedPlanId = localStorage.getItem('selectedPlanId');
    if (savedPlanId && selectedPlan && savedPlanId !== selectedPlan.id) {
        setCurrentPagePlan(1);
    }
  }, [selectedPlan, setCurrentPagePlan]);

  
  
  const initialSelection = headers.reduce((acc, column) => {
    acc[column.key] = defaultColumnSelections.PlanMapPage.includes(column.key);
    return acc;
  }, {});

  const [selectedColumns, setSelectedColumns] = useState(initialSelection);
  const filteredHeaders = headers.filter(header => selectedColumns[header.key]);

  console.log("currentUserId from PlanMapPage", currentUserId);

  // Fetch plans for the currently logged-in user
  useEffect(() => {

    // check if no data fetched yet
    if (plans.length === 0) {

      // Define asynchronous function within useEffect
      const fetchPlansForLoggedInUser = async () => {
        const planResult = await fetchDataPlans(pb, currentUserId);
        console.log("Fetched plans:", planResult);
        setPlans(planResult.data);

        // fetch from saved plan id if it exists, otherwise fetch from first plan
        const savedPlanId = localStorage.getItem('selectedPlanId');
        if (savedPlanId) {
          const savedPlan = planResult.data.find(plan => plan.id === savedPlanId);
          setSelectedPlan(savedPlan || (planResult.data.length > 0 ? planResult.data[0] : null));
        } else {
          setSelectedPlan(planResult.data.length > 0 ? planResult.data[0] : null);
        }
      };

      fetchPlansForLoggedInUser();
    }
  }, [currentUserId, pb]);

      // Define asynchronous function within useEffect
      const fetchPlanData = async () => {
        if (selectedPlan) {
          const planData = await fetchDataPlanTeren(pb, currentPagePlan, pageSizePlan, selectedPlan.id);
          // Update state variables
          //setCurrentPagePlan(planData.currentPage);
          setTotalItemsPlan(planData.totalItems);
          //setShouldFetchDataPlan(false);  // Disable data fetching after data is fetched

          console.log("------// planData", planData);

          // Flatten the plan data
          const flattenedData = planData.data.map(item => {
            const flattenedItem = {
              ...item,
              ...item.expand,
              ...item.expand.teren,
            };
          
            // Explicitly set "prikljucki" to ensure it's preserved as an array of objects
            // This assumes "prikljucki" is a direct property of `item`; adjust if it's nested differently
            if (item.prikljucki) {
              flattenedItem.prikljucki = item.prikljucki;
            }
          
            return flattenedItem;
          });
          //console.log("flattenedData", flattenedData);

          // print both original and flattened data
          //console.log("Original data:", planData.data);
          //console.log("Flattened data:", flattenedData);


          const sortedData = multiFieldSort(flattenedData, ['postni_okolis_naziv', 'ulica_naziv', 'hs_stevilka', 'st_stan_ali_dela']);
          console.log("Sorted data:", sortedData);

          setFlattenedPlanData(sortedData);
        }
      };



  // Fetch detailed plan data based on selected plan and pagination
  useEffect(() => {

    // check if no data fetched yet
    //if (flattenedPlanData.length === 0) {

      fetchPlanData();
    //}
  }, [pb, selectedPlan]);


    // Fetch detailed plan data
    // TODO: This is a workaround to force the table to refetch data
    // TODO: You should update the data offline
    useEffect(() => {

      if (refetchPlanData) {
  
  
        fetchPlanData();
        setRefetchPlanData(false);
      }
    }, [refetchPlanData]);

  // function to sort data by multiple fields
  // Usage
  // const sortedData = multiFieldSort(flattenedPlanData, ['naselje_naziv', 'ulica_naziv', 'hs_stevilka', 'st_stan_ali_dela']);
  const multiFieldSort = (data, fields) => {
    return [...data].sort((a, b) => {
      for (let i = 0; i < fields.length; i++) {
        if (a[fields[i]] < b[fields[i]]) {
          return -1;
        } else if (a[fields[i]] > b[fields[i]]) {
          return 1;
        }
      }
      return 0; // If all fields are equal
    });
  };

  
  // Handles row button click to display modal
  const handleRowButtonClick = (rowData) => {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  };
  
  // Update total items when data is fetched or sorted
  useEffect(() => {
    setTotalItemsPlan(flattenedPlanData.length);
  }, [flattenedPlanData]);


  // Calculate the subset of data to display on the current page
  const indexOfLastItem = currentPagePlan * pageSizePlan;
  const indexOfFirstItem = indexOfLastItem - pageSizePlan;
  const currentDataForDisplay = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  

  // Main component return
  return (
    <div>
      <ComboBox
        id="plans-dropdown"
        label="Izberi plan"
        items={plans}
        itemToString={item => item ? `${item.ime}` : ''}
        selectedItem={selectedPlan}
        onChange={({ selectedItem }) => handlePlanSelection(selectedItem)}
      />
    <TextInput
      id="search-input"
      labelText="Iskanje"
      placeholder="Iskanje..."
      value={searchTerm}
      onChange={handleSearchChange}
    />
      <TableComponent 
        tableName="Plan terena"
        data={currentDataForDisplay}
        filteredHeaders={filteredHeaders}
        currentPage={currentPagePlan}
        totalItems={totalItemsPlan}
        pageSize={pageSizePlan}
        handlePaginationChange={handlePaginationChangePlan}
        showSelect={false}
        renderRowButton={(rowData) => (
          <button onClick={() => handleRowButtonClick(rowData)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M5.646 4.646a.5.5 0 0 1 0 .708L8.293 8l-2.647 2.646a.5.5 0 1 1-.708-.708l2.646-2.646a.5.5 0 0 0 0-.708L5.646 4.646z"/>
            </svg>
          </button>
        )}
        isAgent={true}
      />
      <div style={mapStyleHorizontal} >
        <MapComponent data={flattenedPlanData} />
      </div>
      {selectedRowData && (
        <Modal
          open={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          modalHeading={selectedRowData.ime}
          primaryButtonText="Close"
          secondaryButtonText="Cancel"
          onSecondarySubmit={() => setIsModalOpen(false)}
        >
          <p>{selectedRowData.opis}</p>
        </Modal>
      )}
      <Outlet />
    </div>
  );
};

// Export the component
export default PlanMapApp;