import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import fetchDataTeren from './fetchDataTeren';
import fetchDataUsers from './fetchDataUsers';
import fetchDataPlans from './fetchDataPlans';
import fetchDataPlanTeren from './fetchDataPlanTeren';
import TableComponent from './TableComponent';
//import TableComponentPlan from './TableComponentPlan';
import FilterForm from './FilterForm';
import ColumnSelector from './ColumnSelector';
import MapComponent from './MapComponent';
import { Button, Dropdown, DatePicker, DatePickerInput, TextInput, Toggle, ComboBox } from '@carbon/react';
import { Save, Checkmark } from '@carbon/icons-react';
import { mapStyleVertical, tableStyle, sidebarStyle } from './styles';
import { useAuth } from '../../AuthContext';
import { headers, defaultColumnSelections } from '../headers';



/**
 * TerenTable component that displays a table of teren data and allows the user to create new plans for selected users.
 * @returns {JSX.Element} TerenTable component
 */
const TerenTable = () => {
  const { isAuthenticated, currentUserId, pb, login, logout } = useAuth();
  const [terenData, setTerenData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [currentPagePlan, setCurrentPagePlan] = useState(1);
  const [totalItemsPlan, setTotalItemsPlan] = useState(0); // Total number of items
  const [pageSize, setPageSize] = useState(10); // Number of items per page
  const [pageSizePlan, setPageSizePlan] = useState(10); // Number of items per page
  //const pb = new PocketBase('http://127.0.0.1:8090');
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [shouldFetchDataPlan, setShouldFetchDataPlan] = useState(false); // this is just a trick to force rerender
  const [isInputShown, setIsInputShown] = useState(false); // Whether input box is shown
  const [newPlanText, setNewPlanText] = useState('');     // Text for the new plan

  const [selectedRows, setSelectedRows] = useState([]); // To keep track of selected rows
  const [planData, setPlanData] = useState([]); // Mock data for Plan_teren table
  const [flattenedPlanData, setFlattenedPlanData] = useState([]);
  const [plans, setPlans] = useState([]); // Mock data for Plans list
  const [selectedPlan, setSelectedPlan] = useState(null); // Currently selected Plan
  const [newPlanDate, setNewPlanDate] = useState(null); // Date for the new plan
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);


  const handleCreateNewPlan = () => {
    setIsInputShown(true); 
  };

  const handleInputChange = (e) => {
    setNewPlanText(e.target.value);
  };

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    const YYYY = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const DD = String(date.getDate()).padStart(2, '0');
  
    return `${YYYY}-${MM}-${DD} 12:00:00.000Z`;
  }

  const formatDateString = (dateString) => {
    try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "-";
    }
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
    } catch (error) {
      console.error("Error formatting date string:", error);
      return "";
    }
  };
  
  const handleNewPlanClick = async () => {
    if (newPlanText.trim()) {
      // Save the plan to the database
      const data = {
        agent: selectedUser.id,  // Assuming selectedUser contains the selected user object
        planiran_datum: formatDate(newPlanDate),
        ime: newPlanText
      };
      
      try {
        const record = await pb.collection('plan').create(data);
        
        // Update the local plans state if the record was successfully saved
        if (record && record.ime) {
          setPlans(prevPlans => [...prevPlans, record]);
          setSelectedPlan(record); // Set the newly added plan as the selected plan
        }
  
      } catch (error) {
        console.error("Error saving plan to database:", error);
      }
  
      // Hide the input and reset the newPlanText
      setIsInputShown(false);
      setNewPlanText(''); 
    }
  };
  
  
  

  const [filters, setFilters] = useState({
    opt_vrsta_subjekta_id: [],
    obcina_naziv: "",
    naselje_naziv: "",
    ulica_naziv: "",
    hs_stevilka: "",
    postni_okolis_sifra: "",
    postni_okolis_naziv: "",
    st_stan_ali_dela: "",
  });

  //const [selectedColumns, setSelectedColumns] = useState(headers);

  /*
    // Initialize selectedColumns as an object
    const [selectedColumns, setSelectedColumns] = useState(
      headers.reduce((acc, column) => {
        acc[column.key] = true;
        return acc;
      }, {})
    );*/

  const initialSelection = headers.reduce((acc, column) => {
    acc[column.key] = defaultColumnSelections.TerenTable.includes(column.key);
    return acc;
  }, {});
  
    const [selectedColumns, setSelectedColumns] = useState(initialSelection);
  
  const handlePaginationChange = ({ page, pageSize }) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    setShouldFetchData(true);  // Enable data fetching when pagination changes
  };

  const handlePaginationChangePlan = ({ page, pageSize }) => {
    setCurrentPagePlan(page);
    setPageSizePlan(pageSize);
    setShouldFetchDataPlan(true);  // Enable data fetching when pagination changes
  };

  const applyFilters = () => {
    //fetchData(currentPage, pageSize, filters);
    setShouldFetchData(true); 
  };

    // This effect runs every time selectedPlan changes
    useEffect(() => {
      const fetchAndSetPlanData = async () => {
        setShouldFetchDataPlan(false);
        // Ensure selectedPlan is present before fetching
        if (selectedPlan) {
            const planData = await fetchDataPlanTeren(pb, currentPagePlan, pageSizePlan, selectedPlan.id);
            //console.log('Fetched planData:', planData);
            console.log('-----------------------Fetched planData:', planData.data);
            setPlanData(planData.data);
            setCurrentPagePlan(planData.currentPage);
            setTotalItemsPlan(planData.totalItems);

            const flattenedData = planData.data.map(item => ({
              //original: item,
              ...item,
              ...item.expand,
              ...item.expand.teren
            }));
            setFlattenedPlanData(flattenedData);
        } else {
            setPlanData([]); 
            setCurrentPagePlan(1);
            setTotalItemsPlan(0);
            setFlattenedPlanData([]);
        }
      };
    
      fetchAndSetPlanData();
      // shouldFetchDataPlan is just a trick to force rerender; do not check its value
    }, [selectedPlan, shouldFetchDataPlan]);
    
  useEffect(() => {
    const fetchUsers = async () => {
      const userResult = await fetchDataUsers(pb);
      setUserData(userResult.data);
      setUsers(userResult.data);
    };
  
    fetchUsers();
  }, []); // An empty dependency array ensures this useEffect runs only once

   useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isInputShown) {
        setIsInputShown(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isInputShown]);
    

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      if (shouldFetchData) {
        const result = await fetchDataTeren(pb, currentPage, pageSize, filters);

        // print the result data
        //console.log('Fetched data:', result.data);

        setTerenData(result.data);
        setCurrentPage(result.currentPage);
        setTotalItems(result.totalItems);
        setShouldFetchData(false);
      }
    };
    fetchDataAndUpdateState();
  // Intentionally not including 'filters' in the dependency array because
  // I do not want to fetch data when filters change. I only want to fetch
  // data when the user clicks the apply button.
  }, [shouldFetchData, currentPage, pageSize, pb]);

  useEffect(() => {
    console.log("TableComponent - selectedRows!");
    console.log(selectedRows);
  }, [selectedRows]);

  // Filter headers to only include selected columns
  const filteredHeaders = headers.filter(header => selectedColumns[header.key]);
  // print all available headers
  console.log('TerenTable headers', headers);
  // print filtered headers
  console.log('TerenTable filteredHeaders', filteredHeaders);

  const handleDateChange = (eventOrDate) => {
    // Check if the provided value is an instance of Date
    setNewPlanDate(eventOrDate[0]);
  };
  

// primerjava za obstoječe vrstice dejansko ne deluje
// koda kljub temu deluje, ker se v bazi ne morejo pojaviti podvojeni vnosi (unique index)
// (catch error v copyToPlan)
// če bo težava s performanco, je treba to popraviti
const copyToPlan = async () => {
  // Assuming 'planData' contains data from 'plan_teren' table.
  // If it doesn't, you'd need another fetch here to get the current records.

  // Extract the combination of plan and teren IDs from existing planData
  const existingPlanTerenCombos = new Set(
    planData.map(item => `${item.plan.id}-${item.teren.id}`)
  );

  // Filter selected rows that are not yet in the 'plan_teren' table.
  const uniqueRows = selectedRows.filter(rowId => {
    const combo = `${selectedPlan.id}-${rowId}`;
    return !existingPlanTerenCombos.has(combo);
  });

  // Now, we only add these uniqueRows to the 'plan_teren' table.
  for (const rowId of uniqueRows) {
    const data = {
      ulica: rowId,
      naselje: terenData[0].naselje_naziv, // Vse ulice naj bi imele isto naselje saj so bile filtriranje po naselju
      vrsta_prikljucka: "Bakrena parica" //TODO - hardcoded dokler ni filtra za priključek
    };
    try {
      const record = await pb.send(`/plan/${selectedPlan.id}`, {
        method: 'POST',
        body: data,
        query: {
          expand: "prikljucki"
        }
      });
      if (record) {
        console.log("Successfully added to plan_teren", record);
      }
    } catch (error) {
      console.error("Error adding to plan_teren:", error);
    }
  }

  // Refetch the data
  setSelectedPlan(prevPlan => ({ ...prevPlan })); // This line will re-trigger the useEffect due to the change in selectedPlan.
};

  
  // User options
  const [users, setUsers] = useState(userData); 

  // Selected user
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    console.log("isSidebarVisible!");
    console.log(isSidebarVisible);
  }, [isSidebarVisible]);

  return (
    <div>
      <div style={{ display: 'flex', minHeight: '100vh' }}>
      <div style={isSidebarVisible ? sidebarStyle : { ...sidebarStyle, display: 'none' }}>
          <h5>Stolpci</h5>
          <div>
            <ColumnSelector
              headers={headers}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
          </div>
          <br />
          <h5>Filtri</h5>
          <FilterForm filters={filters} setFilters={setFilters} applyFilters={applyFilters} />
        </div>
        <div style={tableStyle(isSidebarVisible)}>
          <Toggle 
            size="sm" 
            labelText="" 
            labelA="Skrij filter" 
            labelB="Prikaži filter" 
            defaultToggled 
            id="toggle-sidebar" 
            onToggle={(e) => setIsSidebarVisible(!isSidebarVisible)}
          />
          <TableComponent 
            tableName="Teren"
            data={terenData}
            filteredHeaders={filteredHeaders}
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            handlePaginationChange={handlePaginationChange}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            showSelect={true}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={copyToPlan} disabled={!selectedPlan} size="md">
              Prenesi v plan
            </Button>
            <Button onClick={handleCreateNewPlan} disabled={!selectedUser} size="md">
              Ustvari nov plan
            </Button>
            <ComboBox
              style={{ flex: 1 }}
              id="users-dropdown"
              label="Izberi uporabnika"
              items={users}
              itemToString={item => item ? item.name : ''}
              selectedItem={selectedUser}
              onChange={async ({ selectedItem }) => {
                setSelectedUser(selectedItem);
                if (selectedItem && selectedItem.id) {
                  try {
                    const planResult = await fetchDataPlans(pb, selectedItem.id);
                    setPlans(planResult.data);

                    // If there are any plans, set the selected plan to the first one. Otherwise, set it to null.
                    setSelectedPlan(null);
                  } catch (error) {
                    console.error('Error fetching plans:', error);
                  }
                }
              }}
            />
            {isInputShown ? (
              <div>
                {/* <DatePicker
                  datePickerType="single"
                  dateFormat="d.m.Y"
                  onChange={(eventOrDate) => handleDateChange(eventOrDate)}
                  onClose={function noRefCheck(){}}
                >
                  <DatePickerInput
                    id="date-picker-single"
                    labelText="Ustvari nov plan"
                    onChange={(event) => handleDateChange(event)}
                    onClose={function noRefCheck(){}}
                    placeholder="dd.mm.llll"
                    autoComplete="off" // This disables autofill
                  />
                </DatePicker> */}

              
                <div
                  style={{
                    width: 288
                  }}
                >
                  <TextInput
                    className="input-test-class"
                    id="text-input-1"
                    labelText=""
                    onChange={handleInputChange}
                    onClick={function noRefCheck(){}}
                    placeholder="Ime plana"
                    width={300}
                    size="md"
                    type="text"
                    value={newPlanText}
                    autoFocus
                  />
                </div>
                <Button
                  onClick={handleNewPlanClick}
                  disabled={!selectedUser}
                  hasIconOnly
                  iconDescription="Shrani nov plan"
                  renderIcon={Checkmark}
                  size="md"
                  style={{ width: 288 }}
                />
              </div>
            ) : (
              <ComboBox
                style={{flex: 1, width: 288}}
                id="plans-dropdown"
                label="Izberi plan"
                items={plans}
                itemToString={item => item ? `${item.ime}` : ''}
                selectedItem={selectedPlan}
                onChange={({ selectedItem }) => setSelectedPlan(selectedItem)}
              />
            )}
          </div>
          <TableComponent
            tableName="Plan"
            data={flattenedPlanData}
            filteredHeaders={filteredHeaders}
            currentPage={currentPagePlan}
            totalItems={totalItemsPlan}
            pageSize={pageSizePlan}
            handlePaginationChange={handlePaginationChangePlan}
            showSelect={false}
          />
        </div>
        <div style={mapStyleVertical} >
          <MapComponent data={terenData} />
        </div>
      </div>
    </div>
  );
};

export default TerenTable;
