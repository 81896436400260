import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useAuth } from '../../AuthContext';
import { TextInput, FileUploader, ToastNotification, Button } from '@carbon/react';
import './_xls-upload-page.scss';

function XLSUploadPage() {
  const { pb } = useAuth(); // Using PocketBase client from AuthContext
  const [tableName, setTableName] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [files, setFiles] = useState(null);
  const [importMessage, setImportMessage] = useState('');
  const [consoleMessages, setConsoleMessages] = useState([]); // State to store console messages
  const [isConsoleVisible, setIsConsoleVisible] = useState(true); // Console hidden by default
const [planId, setPlanId] = useState(''); // State for plan ID input

  const toggleConsoleVisibility = () => {
    setIsConsoleVisible(!isConsoleVisible);
};

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = jsonData.shift(); // First row as headers
    const records = jsonData.map(row => {
      const record = {};
      headers.forEach((header, index) => {
        record[header] = row[index];
      });
      return record;
    });
    setFiles(records);
  };


  const importTerenToPocketBase = async (records) => {
    let numImported = 0;
    let tempConsoleMessages = []; // Temporary array to hold console messages
    for (let record of records) {
      try {
        console.log(record);
        tempConsoleMessages.push(`Record: ${JSON.stringify(record)}`); // Add record to temp console messages

        // Find records in Teren table
        const resultList = await pb.collection('Teren').getList(1, 500, {
          filter: `postni_okolis_sifra = "${record.postni_okolis_sifra}" && ulica_naziv = "${record.ulica_naziv}" && hs_stevilka = "${record.hs_stevilka}"`,
        });

        // Insert new records into plan_teren table
        for (let terenRecord of resultList.items) {
          const newRecordData = {
            plan: planId,
            teren: terenRecord.id,
          };
          const result = await pb.collection('plan_teren').create(newRecordData);
          numImported++;
          console.log('Record inserted successfully into plan_teren:', result);
          tempConsoleMessages.push(`${numImported}: Record inserted successfully into plan_teren: ${result.id}`); // Add success message to temp console messages
        }
        setImportMessage('Records processed successfully.');
      } catch (error) {
        console.error('Error processing record:', error);
        tempConsoleMessages.push(`Error processing record: ${error.message}`); // Add error message to temp console messages
        setImportMessage('Error processing record: ' + error.message);
      }
    }
    setConsoleMessages(tempConsoleMessages); // Update state with all console messages
    setShowToast(true); // Show toast notification
  };

  const importDataToPocketBase = async (records) => {
    let tempConsoleMessages = []; // Temporary array to hold console messages
    for (let record of records) {
      try {
        console.log(record);
        tempConsoleMessages.push(`Record: ${JSON.stringify(record)}`); // Add record to temp console messages
        const result = await pb.collection(tableName).create(record);
        console.log('Record imported successfully:', result);
        tempConsoleMessages.push(`Record imported successfully: ${result.id}`); // Add success message to temp console messages
        setImportMessage('Record imported successfully: ' + result.id);
      } catch (error) {
        console.error('Error importing record:', error);
        tempConsoleMessages.push(`Error importing record: ${error.message}`); // Add error message to temp console messages
        setImportMessage('Error importing record: ' + error.message);
      }
    }
    setConsoleMessages(tempConsoleMessages); // Update state with all console messages
  };

  return (
    <div>
            <div className="pageLayout">
      <div className="content">
    {showToast && (
        <ToastNotification
          caption=""
          iconDescription="Zapri obvestilo"
          kind="success"
          lowContrast={false}
          onClose={() => setShowToast(false)}
          subtitle={importMessage}
          timeout={7000}
          title="Zapis uspešen"
        />
      )}
      <TextInput
        id="table-name-input"
        labelText=""
        placeholder="Enter PocketBase Table Name"
        value={tableName}
        onChange={(e) => setTableName(e.target.value)}
      />
      <FileUploader
        labelTitle=""
        labelDescription="Upload your file"
        buttonLabel="Add file"
        filenameStatus="edit"
        accept={['.xlsx', '.xls']}
        onChange={handleFileChange}
      />
      <Button onClick={() => importDataToPocketBase(files)}>Import</Button>
      <Button onClick={toggleConsoleVisibility}>
                    {isConsoleVisible ? 'Hide Console' : 'Show Console'}
                </Button>

                <TextInput
            id="plan-id-input"
            labelText=""
            placeholder="Enter Plan ID"
            value={planId}
            onChange={(e) => setPlanId(e.target.value)}
          />
                <Button onClick={() => importTerenToPocketBase(files)}>Uvozi teren</Button>
      </div>
      {isConsoleVisible && (
      <div className="console">
        <h3>Console Output:</h3>
        {consoleMessages.map((msg, index) => (
          <p key={index}>{msg}</p>
        ))}
              </div>
      )}
    </div>
      </div>

  );
}

export default XLSUploadPage;