import { useLocation } from 'react-router-dom';
import { useRef, useState, useEffect } from 'react';
import SignaturePad from 'signature_pad';
import { PDFDocument } from 'pdf-lib';
import { Button, StructuredListWrapper, StructuredListHead, StructuredListBody, StructuredListRow, StructuredListCell } from '@carbon/react';
import { Save, Checkmark } from '@carbon/icons-react';
import PdfViewer from './PdfViewer';
import PdfEditor from './PdfEditor';
import config from '../../config.json';
import './_document-sign-page.scss';
import PdfViewerComponent from './PdfViewerComponent';
import SimplePdfViewer from './SimplePdfViewer';
import PdfViewerWithOverlay from './PdfViewerWithOverlay';
import signatureConfig from '../../signature_config.json';
import {
Edit,
Erase,
SendAlt
} from '@carbon/icons-react'

const DocumentSignPage = ({ documents, onComplete, toEmails, handleGdprSigned }) => {
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
  //const [documentSignatures, setDocumentSignatures] = useState([]);
  const location = useLocation();
  const [pdfFile, setPdfFile] = useState(null); // current PDF file URL
  //const [pdfData, setPdfData] = useState(null); // PDF data passed as prop
  const [documentType, setDocumentType] = useState(null); // Default document type
  const [signaturePad, setSignaturePad] = useState(null);
  const canvasRef = useRef(null);
  const signaturePadRef = useRef(null);
  const [currentSignatureIndex, setCurrentSignatureIndex] = useState(0);
  const [signatures, setSignatures] = useState([]);
  const [signatureLocations, setSignatureLocations] = useState([]);
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [allSignedDocuments, setAllSignedDocuments] = useState([]);
  const [isAllSigned, setIsAllSigned] = useState(false);

  const loadDocument = async (document) => {
    const response = await fetch(document.url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(document.data)
    });
    const blob = await response.blob();
    setPdfFile(URL.createObjectURL(blob));
    setDocumentType(document.data["form_selection"]);
    //setDocumentSignatures(signatureConfig[document.form_selection]);
    //setSignatures(signatureConfig[document.data["form_selection"]]);
    if (canvasRef.current) {
      signaturePadRef.current = new SignaturePad(canvasRef.current);
    } else {
      console.error('Canvas element is not available');
    }
    
    console.log('document.data["form_selection"]', document.data["form_selection"]);
    const signatureConfigData = signatureConfig[document.data["form_selection"]];
    console.log('signatureConfigData', signatureConfigData);
    if (signatureConfigData) {
      const orderedSignatures = Object.entries(signatureConfigData)
        .map(([key, value]) => ({ key, ...value }))
        .sort((a, b) => a.page - b.page || a.coordinates[1] - b.coordinates[1]); // Sort by page and then by Y-coordinate
      setSignatureLocations(orderedSignatures);
      setCurrentSignatureIndex(0);
      console.log('orderedSignatures', orderedSignatures);
    } else {
      console.log('Signature config data not found');
    }
  };

  const moveToNextDocument = () => {
    const currentDocument = documents[currentDocumentIndex];
  
    if (currentDocument.data["form_selection"] === "gdpr") {
      handleGdprSigned(true);
    }
  
    const nextIndex = currentDocumentIndex + 1;
    if (nextIndex < documents.length) {
      setCurrentDocumentIndex(nextIndex);
      setSignatures([]);
      setCurrentSignatureIndex(0);
      loadDocument(documents[nextIndex]);
    } else {
      setIsAllSigned(true);
      //sendAllDocuments();
    }
  };

  useEffect(() => {
    console.log('documents', documents);
    if (documents.length > 0) {
      loadDocument(documents[currentDocumentIndex]);
    }
  }
  , [documents, currentDocumentIndex]);

  /*
  useEffect(() => {
  // Initialize signature pad
  setSignaturePad(new SignaturePad(canvasRef.current));

    // Fetch the PDF file
    // Check if there's PDF data passed in location state
    if (location.state?.pdfData) {
      // Assume pdfData is a blob URL, but you could adjust based on your data format
      setPdfFile(location.state.pdfData);
      console.log("Fetching the PDF file from location state");
    } else {
      console.log("Fetching the PDF file");
      fetch('/zahteva-za-prenos-narocniske-stevilke-k-drugemu-operaterju.pdf')
        .then(response => response.blob())
        .then(blob => {
          const fileURL = URL.createObjectURL(blob);
          setPdfFile(fileURL);
        })
        .catch(error => {
          console.error('Error fetching the PDF:', error);
        });
    }
  }, []);*/

  // Function to initialize the signature pad
  const initSignaturePad = () => {
    if (canvasRef.current) {
      setSignaturePad(new SignaturePad(canvasRef.current));
    }
  };

  useEffect(() => {
    if (canvasRef.current) {
      setSignaturePad(new SignaturePad(canvasRef.current));
    }
  }, []);
/*  
  const sendAllDocuments = async () => {
    const formData = new FormData();
  
    allSignedDocuments.forEach((doc, index) => {
      formData.append(`pdf_${index}`, doc.pdfBlob, `document_${index}.pdf`);
      formData.append(`form_selection_${index}`, doc.documentType);
      
      doc.signatures.forEach((sig, sigIndex) => {
        formData.append(`signature_${index}_${sigIndex}`, sig.blob, `${sig.key}.png`);
      });
    });
  
    toEmails.forEach((email, index) => {
      formData.append(`to_emails[${index}]`, email);
    });
  
    try {
      const response = await fetch(config.signatureServiceUrl, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        console.log("All PDFs merged with signatures successfully.");
        onComplete(allSignedDocuments);
      } else {
        console.error("Failed to merge PDFs with signatures. Status Code:", response.status);
      }
    } catch (error) {
      console.error('Error sending all documents:', error);
    }
  };
*/
  const sendAllDocuments = async () => {
    onComplete(allSignedDocuments);
  };

const applySignatureToPDF = async () => {
  try {
    const signatureBlob = await fetch(signaturePad.toDataURL()).then(res => res.blob());
    signatures.push({ blob: signatureBlob, ...signatureLocations[currentSignatureIndex] });

    if (currentSignatureIndex < signatureLocations.length - 1) {
      setCurrentSignatureIndex(currentSignatureIndex + 1);
      signaturePadRef.current.clear();
    } else {
      const pdfBlob = await fetch(pdfFile).then(res => res.blob());
      
      setAllSignedDocuments([...allSignedDocuments, {
        pdfBlob,
        documentType,
        signatures: [...signatures]
      }]);

      setSignedDocuments([...signedDocuments, currentDocumentIndex]);
      
      if (currentDocumentIndex === documents.length - 1) {
        setIsAllSigned(true);
        //sendAllDocuments();
      } else {
        moveToNextDocument();
      }
    }
  } catch (error) {
    console.error('Error applying signature to PDF:', error);
  }
};

/*
        <div>
          <SimplePdfViewer
            pdfBlobUrl={pdfFile}
            signatureLocations={signatureLocations}
            currentSignatureIndex={currentSignatureIndex}
            pageNumberParam={signatureLocations[currentSignatureIndex]?.page}/>
        </div>
*/

  return (
    <div className="layout-container">     
      <div className="signature-section">
        <div className="signature-container">
          <canvas className={signatureLocations[currentSignatureIndex]?.signer === "agent" ? 'signature-pad-agent' : 'signature-pad'} ref={canvasRef} width="635" height="200"></canvas>
          {signatureLocations[currentSignatureIndex]?.signer === "agent" && (
          <div className="agent-overlay">
            <span>PODPIŠE AGENT</span>
          </div>
        )}
        </div>
        <div className="button-container">
          <Button onClick={initSignaturePad} renderIcon={Erase} kind="secondary">Počisti</Button>
          <Button onClick={applySignatureToPDF} renderIcon={Edit} disabled={isAllSigned} kind="primary">Podpiši</Button>
          {isAllSigned && (
            <div className="button-container">
              <Button onClick={sendAllDocuments} renderIcon={SendAlt} kind="primary">
                Zaključi in pošlji
              </Button>
            </div>
          )}
        </div>
        <div>
          <PdfViewerWithOverlay
            pdfBlobUrl={pdfFile}
            signatureLocations={signatureLocations}
            currentSignatureIndex={currentSignatureIndex}
            pageNumberParam={signatureLocations[currentSignatureIndex]?.page}
          />
        </div>
      </div>


      <div className="document-list-section">
        <StructuredListWrapper>
          <StructuredListBody>
            {documents.map((doc, index) => (
              <StructuredListRow
                key={index}
                className={`document-item ${index === currentDocumentIndex ? 'current-document' : ''}`}
                onClick={() => setCurrentDocumentIndex(index)}
              >
                <StructuredListCell>{doc.data["form_selection"] || `Document ${index + 1}`}</StructuredListCell>
                <StructuredListCell>
                  {signedDocuments.includes(index) ? (
                    <Checkmark size={20} className="signed-icon" />
                  ) : (
                    <Edit size={20} className="unsigned-icon" />
                  )}
                </StructuredListCell>
              </StructuredListRow>
            ))}
          </StructuredListBody>
        </StructuredListWrapper>
      </div>
    </div>
  );
};

export default DocumentSignPage;