

const validateTaxNumber = (taxNumber) => {

    // Check if the value is exactly 8 characters long
    const isFormatValid = /^\d{8}$/.test(taxNumber);
    if (!isFormatValid) {
      return false;
    }

    // Extract the digits and the control character
    const digits = taxNumber.slice(0, 7).split('').map(Number);
    const controlChar = taxNumber[7].toUpperCase();

    // Calculate the sum based on the weights
    const weights = [8, 7, 6, 5, 4, 3, 2];
    const sum = digits.reduce((acc, digit, index) => acc + digit * weights[index], 0);

    // Calculate the expected control number
    let expectedControlNumber = 11 - (sum % 11);
    if (expectedControlNumber === 10 || expectedControlNumber === 11) {
      expectedControlNumber = 0;
    }

    // Convert the control number to a character if needed
    const expectedControlChar = expectedControlNumber.toString();

    // Check if the provided control character matches the expected control character
    const isValid = controlChar === expectedControlChar;

    return isValid;
};

export default validateTaxNumber;